/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { TextArea } from "@spotoninc/nexus-react";
import { useField } from "formik";

import { ITextAreaFieldProps } from "./TextAreaField.types";

export function TextAreaField<T>(props: ITextAreaFieldProps<T>) {
    const {
        id,
        name,
        label,
        className,
        formik: { handleChange, handleBlur },
        value,
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isDisabled,
        type,
        placeholder,
        errorMessage,
    } = props;

    const [field, meta] = useField(name);

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    const onChangeField = (e: any): void => {
        handleChange(e);
        onChange(e);
    };

    return (
        <TextArea
            {...field}
            id={id || `${name}-field`}
            value={value}
            onChange={onChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            height={300}
            className={className}
            label={label}
            disabled={isDisabled}
            type={type}
            placeholder={placeholder}
            errorMessage={meta.touched ? errorMessage ?? meta.error : ""}
        />
    );
}

export default TextAreaField;
