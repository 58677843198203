import React from "react";
import { useTranslation } from "react-i18next";
import { BodyText } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { ErrorIcon } from "app/icons";

import styles from "./ErrorPage.module.scss";

export const ErrorNotFound = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.Container}>
            <>
                <div className={clsx(styles.Content)}>
                    <main className={clsx(styles.Main)}>
                        <div className={styles.Main___title}>
                            <h1 className="text-[30px] tablet:text-[48px] leading-[45px] tablet:leading-[72px] font-bold">
                                {t("oops")}
                            </h1>
                            <BodyText
                                size="medium"
                                className={styles.Main_subtitle}
                            >
                                {t("notFoundDescription")}
                            </BodyText>
                        </div>
                        <div className="flex flex-center tablet:flex-start h-full pt-0 tablet:pt-[90px]">
                            <div className={styles.Main_imageContainer}>
                                <ErrorIcon />
                            </div>
                        </div>
                    </main>
                </div>
            </>
        </div>
    );
};
