import React from "react";
import { BodyText } from "@spotoninc/nexus-react";

import { IBusinessStep } from "features/application-wizard";

import styles from "../../BusinessInformationForm/BusinessInformationForm.module.scss";

interface ISubLabelLegal {
    businessStep: IBusinessStep;
}

export const SubLabelLegal = (props: ISubLabelLegal) => {
    const { businessStep } = props;
    const { dbaAddress, dbaContact } = businessStep;
    const { businessName: dbaName } = dbaContact;
    const {
        address1: dbaAddress1,
        address2: dbaAddress2,
        city: dbaCity,
        state: dbaState,
        zip: dbaZip,
    } = dbaAddress;
    const address = ` ${dbaAddress1}, ${dbaAddress2}${
        dbaAddress2 && ", "
    } ${dbaCity}, ${dbaState} ${dbaZip}`;
    return (
        <BodyText
            size="small"
            className={styles.BusinessInformationForm_sameAddress}
        >
            <strong>{dbaName},</strong>
            {address}
        </BodyText>
    );
};
