import React from "react";
import { useRouteError } from "react-router-dom";
import { BodyText } from "@spotoninc/nexus-react";
import { AxiosError } from "axios";
import clsx from "clsx";

import { IPageComponentProps } from "app/types";
import { useTranslation } from "i18nConfig";

import photoRestaurant1 from "../../images/restaurant-back.webp";
import photoRestaurant2 from "../../images/restaurant-front.webp";
import photoRetail1 from "../../images/retail-back.webp";
import photoRetail2 from "../../images/retail-front.webp";

import styles from "./ErrorPage.module.scss";

const RenderNotAuthorized = () => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-center">
            <h2>{t("oopsNotAuthorized")}</h2>
        </div>
    );
};

export const ErrorPage = ({
    appStore,
}: Pick<IPageComponentProps, "appStore">) => {
    const { t } = useTranslation();
    const error = useRouteError() as AxiosError["response"];
    const isRestaurant = appStore.state.data.config.isRestaurant;
    const [photoFront, photoBack] = isRestaurant
        ? [photoRestaurant1, photoRestaurant2]
        : [photoRetail1, photoRetail2];

    if (error?.status === 401) {
        return <RenderNotAuthorized />;
    }
    return (
        <div className={styles.Container}>
            <>
                <div className={clsx(styles.Content)}>
                    <main className={clsx(styles.Main)}>
                        <div className={styles.Main___title}>
                            <h1 className="text-[30px] tablet:text-[48px] leading-[45px] tablet:leading-[72px] font-bold">
                                {t("errorPageTitle")}
                            </h1>
                            <BodyText
                                size="medium"
                                className={styles.Main_subtitle}
                            >
                                {t("errorPageDescription")}
                            </BodyText>
                        </div>
                        <div className={styles.Main___images}>
                            <div className={styles.Main_imageContainer}>
                                <img
                                    className={clsx(
                                        styles.Main_backImage,
                                        styles.Main_image,
                                    )}
                                    src={photoFront}
                                    alt="homepageFrontImage"
                                />
                                <img
                                    className={clsx(
                                        styles.Main_frontImage,
                                        styles.Main_image,
                                    )}
                                    src={photoBack}
                                    alt="homepageBackImage"
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </>
        </div>
    );
};
