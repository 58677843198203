import {
    IBillingStep,
    ILinkedAccount,
    LinkingType,
} from "features/application-wizard";
import { IManualLinkAccountFormData } from "features/common";
import {
    API_ENDPOINTS,
    httpCore,
    httpFileCore,
    uploadCore,
} from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultBillingStep, defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";
import { srcToFile } from "../helpers/srcToFile";

export const billingStepReducers = {};

export const billingStepAsyncReducers = {
    async uploadCapturePhoto(
        currentState: IAppStateType,
        imageFile: File,
    ): Promise<typeof defaultReducer> {
        return (state: IAppStateType) => ({
            ...state,
            billingStep: {
                ...state.billingStep,
                billingSelector: {
                    ...state.billingStep.billingSelector,
                    [LinkingType.Manual]: {
                        ...state.billingStep.billingSelector[
                            LinkingType.Manual
                        ],
                        uploadedCheck: imageFile,
                    },
                },
            },
        });
    },
    async submitPhotoCheck(
        currentState: IAppStateType,
        updateStepper = true,
    ): Promise<typeof defaultReducer> {
        try {
            const imageFileData = new FormData();
            const { uploadedCheck } =
                currentState.billingStep.billingSelector.manual;

            if (uploadedCheck) {
                imageFileData.append("file", uploadedCheck);
                const response = await httpFileCore.post(
                    API_ENDPOINTS.signup.BillingStepUploadCheck,
                    imageFileData,
                    { headers: { "Content-Type": "multipart/form-data" } },
                );
                const { stepper } = response.data;

                const file = await srcToFile(
                    API_ENDPOINTS.signup.BillingStepFormCheck,
                );
                return (state: IAppStateType) => ({
                    ...state,
                    billingStep: {
                        ...state.billingStep,
                        billingSelector: {
                            ...state.billingStep.billingSelector,
                            [LinkingType.Manual]: {
                                ...state.billingStep.billingSelector[
                                    LinkingType.Manual
                                ],
                                uploadedCheck: undefined,
                            },
                        },
                        billingForm: {
                            ...state.billingStep.billingForm,
                            voidedCheckFile: file,
                        },
                    },
                    ...(updateStepper
                        ? getStepperState({ state, stepper })
                        : {}),
                });
            } else {
                throw Error("No file uploaded");
            }
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
    async deleteCheck(
        currentState: IAppStateType,
    ): Promise<typeof defaultReducer> {
        try {
            await httpFileCore.delete(
                API_ENDPOINTS.signup.BillingStepDeleteCheck,
            );
            return (state: IAppStateType) => ({
                ...state,
                billingStep: {
                    ...state.billingStep,
                    billingForm: {
                        ...state.billingStep.billingForm,
                        voidedCheckFile: undefined,
                    },
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
    async toManualForm(
        currentState: IAppStateType,
    ): Promise<typeof defaultReducer> {
        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.BillingStepManual,
            );
            const { stepper } = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
    async setLinkToken(
        currentState: IAppStateType,
        linkToken: string,
    ): Promise<typeof defaultReducer> {
        return (state: IAppStateType) => ({
            ...state,
            billingStep: {
                ...state.billingStep,
                linkToken: linkToken,
            },
        });
    },
    async getLinkToken(
        currentState: IAppStateType,
    ): Promise<typeof defaultReducer> {
        const response = await httpCore.get(
            API_ENDPOINTS.auth.PlaidGetLinkToken,
        );

        if (response.status !== 200) return defaultReducer;

        const linkToken = response.data.linkToken as string;
        localStorage.setItem("linkToken", linkToken);

        return (state: IAppStateType) => ({
            ...state,
            billingStep: {
                ...state.billingStep,
                billingSelector: {
                    ...state.billingStep.billingSelector,
                    [LinkingType.Plaid]: {
                        ...state.billingStep.billingSelector[LinkingType.Plaid],
                        linkToken: linkToken,
                    },
                },
            },
        });
    },

    async setPublicToken(
        currentState: IAppStateType,
        token: string,
    ): Promise<typeof defaultReducer> {
        return (state: IAppStateType) => ({
            ...state,
            billingStep: {
                ...state.billingStep,
                billingSelector: {
                    ...state.billingStep.billingSelector,
                    linkingType: LinkingType.Plaid,
                    [LinkingType.Plaid]: {
                        ...state.billingStep.billingSelector[LinkingType.Plaid],
                        token,
                    },
                },
            },
        });
    },

    async sendPublicToken(currentState: IAppStateType) {
        try {
            const { token } = currentState.billingStep.billingSelector.plaid;
            const response = await httpCore.post(
                API_ENDPOINTS.auth.PlaidAccountRegister,
                { publicToken: token },
            );
            const { stepper, status } = response.data;
            if (status === "success") {
                return (state: IAppStateType) => ({
                    ...state,
                    ...getStepperState({ state, stepper }),
                });
            }
            return defaultReducer;
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async getLinkedAccount(currentState: IAppStateType) {
        try {
            const response = await httpCore.get(
                API_ENDPOINTS.signup.BillingStepSuccess,
            );

            const { manuallyLinked, plaid } = response.data;
            const accountSource =
                manuallyLinked?.mask && manuallyLinked?.bankName
                    ? manuallyLinked
                    : plaid;

            const account: ILinkedAccount | undefined =
                accountSource?.mask && accountSource?.accountName
                    ? {
                          accountName: accountSource.accountName,
                          mask: accountSource.mask,
                          routingNumber: accountSource.routingNumber,
                          bankName: accountSource.bankName,
                      }
                    : undefined;

            return (state: IAppStateType) => ({
                ...state,
                billingStep: {
                    ...state.billingStep,
                    account: account,
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async deleteLinkedAccount(currentState: IAppStateType) {
        try {
            const response = await httpCore.delete(
                API_ENDPOINTS.signup.BillingStepSuccessDeleteLinkedAccount,
            );
            const { stepper } = response.data;
            return (state: IAppStateType) => ({
                ...state,
                billingStep: defaultBillingStep(),
                ...getStepperState({ state, stepper }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async getManualAccountData(
        currentState: IAppStateType,
        withImage = true,
    ): Promise<typeof defaultReducer> {
        try {
            const response = await uploadCore.get<
                IBillingStep["billingForm"]["fields"]
            >(API_ENDPOINTS.signup.BillingStepForm);
            const { ...fields } = response.data;
            let file: File | null;
            if (withImage) {
                file = await srcToFile(
                    API_ENDPOINTS.signup.BillingStepFormCheck,
                );
            }
            return (state) => ({
                ...state,
                billingStep: {
                    ...state.billingStep,
                    billingForm: {
                        ...state.billingStep.billingForm,
                        fields: {
                            ...state.billingStep.billingForm.fields,
                            ...fields,
                        },
                        ...(file ? { voidedCheckFile: file } : {}),
                    },
                },
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async setManualAccount(
        currentState: IAppStateType,
        hydratedValues: IManualLinkAccountFormData,
    ) {
        const { accountNumber, routingNumber, bankName, accountName } =
            hydratedValues;

        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.BillingStepForm,
                {
                    accountNumber,
                    routingNumber,
                    bankName,
                    accountName,
                },
            );
            const { stepper } = response.data;
            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
    async setBillingStepAsCompleted(currentState: IAppStateType) {
        try {
            const response = await httpCore.post(
                API_ENDPOINTS.signup.BillingStepSuccess,
            );
            const { stepper } = response.data;
            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },
};
