/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import Webcam from "react-webcam";
import { Button } from "@spotoninc/nexus-react";

import { SwitchCamera } from "app/icons";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useTranslation } from "i18nConfig";

import { StepName } from "../Stepper";
import { BlurredLoader } from "./BlurredLoader.component";
import { CheckPhotoCameraProps } from "./CheckPhotoCamera.types";
import { CustomWebcam } from "./CustomWebcam.component";
import { Frame } from "./Frame.component";
import { MissingCameraInfo, NoAccessInfo } from "./UserMessages";

const CheckPhotoCameraDesktop = forwardRef<Webcam, CheckPhotoCameraProps>(
    (
        {
            onCapture,
            isLoading,
            imageOnLoading,
            deviceId,
            devices,
            onSwitchCamera,
            isVideoAllowed,
        }: CheckPhotoCameraProps,
        ref,
    ) => {
        const { t } = useTranslation();
        return (
            <div className="min-h-[401px] rounded flex flex-col gap-6 mt-9">
                <div className="relative h-full w-full">
                    {isLoading && <BlurredLoader />}
                    <CustomWebcam
                        className="w-full h-[18rem] min-w-screen max-w-[unset] object-cover rounded-2xl"
                        ref={ref}
                        isLoading={isLoading}
                        imageOnLoading={imageOnLoading}
                        deviceId={deviceId}
                        isVideoAllowed={isVideoAllowed}
                    />
                    {devices.length > 1 && (
                        <div className="z-20 absolute left-0 top-0 w-full h-full p-10 flex items-end justify-end">
                            <button
                                onClick={() => {
                                    if (onSwitchCamera) {
                                        onSwitchCamera();
                                    }
                                }}
                                className="flex rounded-full border border-white p-2"
                            >
                                <SwitchCamera />
                            </button>
                        </div>
                    )}
                    <div className="absolute top-0 left-0 p-6 w-full h-full">
                        <Frame>
                            {isVideoAllowed === false && <NoAccessInfo />}
                            {isVideoAllowed && devices.length === 0 && (
                                <MissingCameraInfo />
                            )}
                        </Frame>
                    </div>
                </div>
                <div className="flex gap-8 w-full justify-end">
                    <Button
                        className="w-fit flex justify-center"
                        onClick={onCapture}
                        disabled={isLoading || !isVideoAllowed || !deviceId}
                        {...getAnalyticProps(
                            AnalyticElements.TakeAPhotoButton,
                            {
                                step: StepName.BillingSelector,
                                deviceType: "desktop",
                            },
                        )}
                    >
                        {t("takeAPhoto")}
                    </Button>
                </div>
            </div>
        );
    },
);

export default React.memo(CheckPhotoCameraDesktop);
