import React, { ReactNode, useState } from "react";
import { GhostButton, HeadingText } from "@spotoninc/nexus-react";

import { IPageComponentProps } from "app/types";
import { moneyFormat } from "features/utils";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { DetailsModal } from "./DetailsModal.component";
import { attachTotal } from "./helpers";
import { LabelWithDetails, LabelWithToggletip } from "./Labels.component";
import { Section } from "./Section.component";
import { QuoteSections } from "./types";

export const PlatformView = ({
    appStore,
}: IPageComponentProps & {
    children?: ReactNode;
}) => {
    const { t } = useTranslation();
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                platformStep,
                config: { isPayments },
            },
        }) => ({
            platformStep,
            isPayments,
        }),
    );
    const { platformStep } = getState();
    const {
        paymentPlan,
        shippingPlan,
        hardwareTotal,
        implementationTotal,
        deposit,
        monthlyInstallments,
        softwareTotal,
    } = platformStep;
    const withTotal = attachTotal(t);

    const [isDetailsModalOpen, setIsDetailsModalOpen] =
        useState<boolean>(false);
    const [focusSection, setFocusSection] = useState<
        QuoteSections | undefined
    >();
    const onOpenDetails = (section?: QuoteSections) => {
        setFocusSection(section);
        setIsDetailsModalOpen(true);
    };

    return (
        <div className="flex flex-col gap-12 pt-5">
            <DetailsModal
                isOpen={isDetailsModalOpen}
                onClose={() => {
                    setIsDetailsModalOpen(false);
                }}
                data={platformStep}
                scrollToSection={focusSection}
            />
            <div className="flex flex-col text-left gap-4">
                <div className="w-full flex justify-between items-center py-2">
                    <HeadingText size="small">
                        {t("hardwareAndSoftware")}
                    </HeadingText>
                    <GhostButton onClick={() => onOpenDetails()}>
                        {t("viewDetails")}
                    </GhostButton>
                </div>
                <Section
                    title={t("proposalDetails")}
                    items={[
                        {
                            label: t("paymentPlan"),
                            value: paymentPlan,
                        },
                        {
                            label: (
                                <LabelWithToggletip
                                    label={t("shippingPlan")}
                                    info={t("shippingInfo")}
                                />
                            ),
                            value: shippingPlan,
                        },
                        {
                            label: (
                                <LabelWithDetails
                                    label={withTotal(t("hardware"))}
                                    onClick={() =>
                                        onOpenDetails(QuoteSections.HARDWARE)
                                    }
                                />
                            ),
                            value: moneyFormat(hardwareTotal),
                        },
                        {
                            label: (
                                <LabelWithDetails
                                    label={withTotal(t("implementation"))}
                                    onClick={() =>
                                        onOpenDetails(
                                            QuoteSections.IMPLEMENTATION,
                                        )
                                    }
                                />
                            ),
                            value: moneyFormat(implementationTotal),
                        },
                    ]}
                />
                <Section
                    title={t("oneTimeChargesFees")}
                    items={[
                        // @todo replaced hardcoded value with a variable
                        {
                            label: (
                                <LabelWithToggletip
                                    label={t("upfrontBalance")}
                                    info={t("hardwareInfo")}
                                />
                            ),
                            value: "$0.00",
                        },
                        {
                            label: (
                                <LabelWithToggletip
                                    label={t("nonRefundableDeposit")}
                                    info={t("depositInfo")}
                                />
                            ),
                            value: moneyFormat(deposit),
                        },
                    ]}
                />
                <Section
                    title={t("recurringChargesAndFees")}
                    items={[
                        {
                            label: withTotal(t("monthlyInstallments")),
                            value: moneyFormat(monthlyInstallments),
                        },
                        {
                            label: (
                                <LabelWithDetails
                                    label={withTotal(
                                        t("monthlySubscriptionFees"),
                                    )}
                                    onClick={() =>
                                        onOpenDetails(QuoteSections.SOFTWARE)
                                    }
                                />
                            ),
                            value: moneyFormat(softwareTotal),
                        },
                    ]}
                />
            </div>
            <div className="flex flex-col text-left gap-4">
                <HeadingText size="small">
                    {t("processingRatesAndFees")}
                </HeadingText>
                <Section
                    title={`${t("processingRates&Fees")} - ${t("flatPricing")}`}
                    items={[
                        {
                            label: (
                                <span className="break-all">
                                    {t("visaMastercardDiscoverAmericanExpress")}
                                </span>
                            ),
                            // @todo replaced hardcoded value with a variable
                            value: "3.84% / $0.00",
                        },
                    ]}
                />
                <Section
                    title={`${t("processingRates&Fees")} - ${t("dualPricing")}`}
                    items={[
                        {
                            label: t(
                                "changeInMenuPricingToOffsetProcessingFees",
                            ),
                            // @todo replaced hardcoded value with a variable
                            value: "4%",
                        },
                        {
                            label: t("discountWhenCustomerPaysWithCash"),
                            // @todo replaced hardcoded value with a variable
                            value: "3.84%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};
