import React from "react";

type SectionTitleProps = {
    title: string;
};
export const SectionTitle = ({ title }: SectionTitleProps) => (
    <div className="w-full flex justify-between py-4 font-semibold text-xl sticky top-0 bg-white">
        <h3>{title}</h3>
    </div>
);
