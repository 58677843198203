import React, { useContext, useEffect } from "react";
import { BodyText, Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { useTranslation } from "i18nConfig";

import { SiteSurveyContext } from "../../SiteSurveyPage.utils";
import { SuccessImages } from "../SuccessImages";

import styles from "./SiteSurveySuccessPage.module.scss";

export const SiteSurveySuccessPage = () => {
    const { t } = useTranslation();
    const { isRestaurant, opportunityLink } =
        useContext(SiteSurveyContext) || {};

    useEffect(() => {
        document.getElementById("content")?.scrollTo(0, 0);
    }, []);

    const handleRedirectToSalesforce = () => {
        if (opportunityLink) window.location.href = opportunityLink;
    };

    return (
        <div className={styles.ContentWrapper}>
            <div className={styles.Page}>
                <div className={styles.Content}>
                    <h3 className={styles.PageTitle}>
                        {t("siteSurveySuccessTitle")}
                    </h3>
                    <BodyText
                        size="medium"
                        className={clsx(
                            styles.PageSubTitle,
                            "pb-4 whitespace-pre-line",
                        )}
                    >
                        {t("siteSurveySuccessDescription")}
                    </BodyText>
                    <Button
                        variant="secondary"
                        className="tablet:mt-5 flex justify-center"
                        onClick={handleRedirectToSalesforce}
                    >
                        {t("goToSalesforce")}
                    </Button>
                </div>
                <SuccessImages isRestaurant={!!isRestaurant} />
            </div>
        </div>
    );
};
