import { AppStore, FormikStore } from "features/store";

export enum TabValues {
    UploadPicture = "upload-picture",
    UseCamera = "use-camera",
}

export type BillingSelectorRootProps = {
    open: () => void;
    // isPlaidReady: boolean;
    onManualEnter: () => void;
    onPhotoCapture?: () => void;
    appStore: AppStore;
    formikStore: FormikStore;
};
