import React from "react";
import { Modal, ModalBody, ModalHeader } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { Breakpoint, useBreakpoint } from "features/hooks";
import { useTranslation } from "i18nConfig";

import { DetailsView } from "./DetailsView.component";
import { IPlatformStep } from "./PlatformView.types";
import { QuoteSections } from "./types";

type DetailsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    data: IPlatformStep;
    scrollToSection?: QuoteSections;
};
export const DetailsModal = (props: DetailsModalProps) => {
    const { t } = useTranslation();
    const { isOpen, data, scrollToSection, onClose } = props;
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    return (
        <Modal
            isOpen={isOpen}
            takeOverModal={isLowerThanTablet}
            className={clsx({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "w-full max-w-[unset] h-[calc(100dvh-2.75rem)] absolute top-[2.75rem] flex flex-col":
                    !isLowerThanTablet,
            })}
        >
            <ModalHeader onClose={onClose}>
                <span className="text-2xl">
                    {isLowerThanTablet
                        ? t("detailsText")
                        : t("hardwareAndSoftwareDetails")}
                </span>
            </ModalHeader>
            <ModalBody className="pt-0 overflow-auto h-full">
                <DetailsView data={data} scrollToSection={scrollToSection} />
            </ModalBody>
        </Modal>
    );
};
