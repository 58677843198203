/* eslint-disable @typescript-eslint/naming-convention */
import React, {
    MouseEventHandler,
    TouchEventHandler,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useClickAway } from "@uidotdev/usehooks";
import clsx from "clsx";
import { IconButton } from "spoton-lib";

import { FileUploadIcon } from "app/icons";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { FileType } from "features/utils";
import { useTranslation } from "i18nConfig";

import { StepName } from "../Stepper";
import {
    ButtonsOverlayProps,
    FileDropzoneProps,
    FileIconButtonProps,
    FileUploadPreviewProps,
} from "./FileUpload.types";
import { convertHeicToJpg, isPdfByType } from "./FileUpload.utils";

export const FileIconButton = (props: FileIconButtonProps) => {
    const { onClick, label, className, iconName, id, ...restProps } = props;
    return (
        <>
            <IconButton
                iconClassname={clsx(
                    "bg-white rounded-full text-black border-none",
                    className,
                )}
                name={iconName ?? ""}
                variant="secondary"
                buttonSize="large"
                disableBorder
                className="!bg-white hover:bg-white active:scale-90 transition-all"
                onClick={onClick}
                buttonProps={{ id, ...restProps }}
            />
            <label
                className="text-xs text-white w-full text-center"
                htmlFor={id}
            >
                {label}
            </label>
        </>
    );
};

export const ButtonsOverlay = (props: ButtonsOverlayProps) => {
    const { className, onEdit, onDelete } = props;
    const { t } = useTranslation();

    const nodeRef = useRef(null);

    return (
        <div
            ref={nodeRef}
            className={clsx(
                "w-full h-full bg-base-base-90/90 flex items-center justify-center",
                className,
            )}
        >
            <div className="flex flex-col gap-[7px]">
                <FileIconButton
                    id="replaceImageButton"
                    onClick={onEdit}
                    label={t("replace")}
                    iconName="EditIcon"
                    {...getAnalyticProps(AnalyticElements.ReplaceImageButton, {
                        step: StepName.BillingForm,
                    })}
                />
            </div>
            <div className="flex flex-col gap-[7px]">
                <FileIconButton
                    id="deleteImageButton"
                    onClick={onDelete}
                    label={t("delete")}
                    className="!text-danger-danger-50"
                    iconName="DeleteIcon"
                    {...getAnalyticProps(AnalyticElements.DeleteImageButton, {
                        step: StepName.BillingForm,
                    })}
                />
            </div>
        </div>
    );
};

export const FileUploadPreview = (props: FileUploadPreviewProps) => {
    const { isDescription, onEdit, onDelete, file } = props;
    const [src, setSrc] = useState<string | null>(null);
    useEffect(() => {
        if (file?.type === FileType.HEIC) {
            convertHeicToJpg(file).then((blob) => {
                if (!Array.isArray(blob)) {
                    setSrc(URL.createObjectURL(blob));
                }
            });
        } else {
            const tempSrc = file && URL.createObjectURL(file);
            setSrc(tempSrc);
        }
        return () => {
            if (src) {
                URL.revokeObjectURL(src);
            }
        };
    }, [file]);

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const ref = useClickAway<HTMLDivElement>(() => {
        setIsOverlayVisible(false);
    });
    const onClickOpenOverlay: MouseEventHandler & TouchEventHandler = (e) => {
        e.preventDefault();
        setIsOverlayVisible(true);
    };

    const onClickCloseOverlay: MouseEventHandler & TouchEventHandler = () => {
        setIsOverlayVisible(false);
    };

    return (
        <div className="relative w-full h-full">
            {isOverlayVisible && (
                <div
                    className="absolute h-full w-full top-0 left-0 animate-fade-in"
                    onMouseLeave={onClickCloseOverlay}
                    ref={ref}
                >
                    <ButtonsOverlay
                        className={clsx("rounded gap-10 transition-all", {
                            "tablet:gap-5": isDescription,
                        })}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </div>
            )}
            {src && (
                <div
                    className="w-full h-full flex justify-center group"
                    onMouseEnter={onClickOpenOverlay}
                    onTouchEnd={onClickOpenOverlay}
                >
                    {props.file instanceof File && isPdfByType(file) ? (
                        <embed
                            className="h-full w-full object-contain"
                            src={`${src}#toolbar=0&navpanes=0`}
                        />
                    ) : (
                        <img
                            className="h-full max-h-full max-w-full object-contain"
                            src={src}
                            alt="voided-check"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export const FileUploadDropzone = (props: FileDropzoneProps) => {
    const { renderPreview, file, isDescription, onChange, dropzoneProps } =
        props;
    const { t } = useTranslation();
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length) {
                onChange(acceptedFiles[0]);
            }
        },
        ...dropzoneProps,
        multiple: false,
    });

    return (
        <>
            <input
                {...getInputProps({
                    multiple: false,
                    className: "w-full h-full",
                    hidden: true,
                })}
            />
            {file ? (
                renderPreview({
                    file,
                    open: () => {
                        open();
                    },
                })
            ) : (
                <div
                    {...getRootProps({
                        className: clsx(
                            "w-full h-full cursor-pointer flex items-center flex-col justify-center text-left",
                            { "px-5 py-[27px]": isDescription },
                        ),
                    })}
                >
                    <div className="flex flex-col justify-center items-center gap-4">
                        <div className="p-4 rounded-full bg-container-neutral-light w-fit h-fit flex items-center justify-center">
                            <FileUploadIcon
                                name={
                                    isDescription
                                        ? "ImageUploadIcon"
                                        : "FileUploadIcon"
                                }
                                className={clsx("text-base-base-70 !h-6 !w-6")}
                            />
                        </div>
                        <div className="w-fit">
                            <span className="text-neutral-60">
                                {`${t("dropYourFile")} `}
                            </span>
                            <span className="text-primary-primary-50 hover:underline">
                                {t("selectFileFromComputer")}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
