import React, { useEffect, useRef, useState } from "react";
import {
    BodyText,
    Button,
    Checkbox,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@spotoninc/nexus-react";
import { IconButton } from "spoton-lib";

import { Breakpoint, useBreakpoint } from "features/hooks";
import { useStepNavigate } from "features/utils";
import { useTranslation } from "i18nConfig";

import { IFasterFundingEnroll } from "./FasterFundingEnroll.types";
import { FasterFundingImages } from "./FasterFundingImages";

const TERMS_URL = "https://www.spoton.com/legal/general-funding-terms/";

export const FasterFundingEnroll = ({
    appStore,
    formikStore,
    isAstraModalOpen,
    isEnrollButtonLoading,
    setIsAstraModalOpen,
    astraLink,
    onEnrollMerchant,
    setShouldShowSkipModal,
    shouldShowSkipModal,
}: IFasterFundingEnroll) => {
    const { t } = useTranslation();
    const astraIframe = useRef<HTMLIFrameElement>(null);
    const { goForward } = useStepNavigate({ appStore });
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const [isAccepted, setIsAccepted] = useState<boolean>(false);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.source === astraIframe.current?.contentWindow) {
                // at the moment Astra redirects to spoton.com which sends postMessage on load
                // so we refresh our app when we capture that. In future Astra itself should
                // fire postMessage on card submission but logic will stay the same
                // if they won't add any message before card submission
                window.location.reload();
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const CloseModalButton = () => (
        <IconButton
            alt="close"
            name="CloseIcon"
            onClick={() => setIsAstraModalOpen(false)}
            selectedName="close"
            variant="secondary"
            disableBorder={true}
            className="absolute right-0 top-0 text-black-black"
        />
    );

    const onModalOpen = () => {
        setShouldShowSkipModal(true);
    };

    const onModalClosed = () => {
        setShouldShowSkipModal(false);
    };

    const EnrollmentCheckbox = () => (
        <Checkbox
            className="flex items-center m-0"
            label={
                <p className="text-sm text-neutral-60 font-medium ml-[-4px] pb-1">
                    {t("fasterFundingCheckboxLabel")}{" "}
                    <a
                        href={TERMS_URL}
                        className="text-primary-primary-60"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("fasterFundingCheckboxAdditionalTerms")}
                    </a>
                </p>
            }
            onChange={() => {
                setIsAccepted(!isAccepted);
            }}
            checked={isAccepted}
        />
    );

    return (
        <div className="flex flex-col justify-between gap-8 pb-10 tablet:flex-row">
            <div className="flex flex-col items-start gap-8">
                <div className="flex flex-col gap-6">
                    <BodyText
                        className="text-left tablet:max-w-[520px]"
                        size="medium"
                    >
                        {t("fasterFundingSubtitle")}
                    </BodyText>
                    <BodyText
                        className="text-left tablet:max-w-[520px]"
                        size="medium"
                    >
                        {t("fasterFundingSubtitle2")}
                    </BodyText>
                </div>
                {!isLowerThanTablet && (
                    <div className="flex flex-col gap-0">
                        <div className="flex flex-col gap-2 tablet:flex-row">
                            <Button
                                variant="secondary"
                                onClick={onModalOpen}
                                disabled={isEnrollButtonLoading}
                                className="w-full tablet:w-auto flex items-center"
                            >
                                {t("fasterFundingSkipButton")}
                            </Button>
                            <Button
                                onClick={onEnrollMerchant}
                                variant="primary"
                                isLoading={isEnrollButtonLoading}
                                disabled={isEnrollButtonLoading || !isAccepted}
                                className="w-full tablet:w-auto flex items-center"
                            >
                                {t("fasterFundingEnrollButton")}
                            </Button>
                        </div>
                        <EnrollmentCheckbox />
                    </div>
                )}
                <Modal
                    isOpen={isAstraModalOpen}
                    onClose={onModalClosed}
                    className="relative w-full h-full max-h-[90%] rounded-b-none tablet:w-[520px] tablet:p-[72px] tablet:pt-0 tablet:rounded-b-lg"
                >
                    <ModalHeader>
                        <CloseModalButton />
                    </ModalHeader>
                    <iframe
                        ref={astraIframe}
                        id="astra-iframe"
                        src={astraLink}
                        name="astra_iframe"
                        height="1045px"
                        width={isLowerThanTablet ? "100%" : "360px"}
                        title="Astra"
                    />
                </Modal>
            </div>
            <FasterFundingImages />
            {!isLowerThanTablet ? (
                <Modal
                    className="h-full w-[520px] max-w-[90vw] max-h-[308px] p-6 flex flex-col rounded-3xl"
                    isOpen={shouldShowSkipModal}
                >
                    <ModalHeader className="m-0 " onClose={onModalClosed}>
                        <div className="w-full flex items-start justify-between">
                            <span className="font-semibold text-2xl">
                                {t("fasterFundingModalHeader")}
                            </span>
                        </div>
                    </ModalHeader>
                    <div className="flex flex-col gap-2 pt-4 h-full">
                        <span>{t("fasterFundingModalContent")}</span>
                        <EnrollmentCheckbox />
                        <div className="flex justify-end gap-4 pt-1">
                            <Button
                                onClick={async () =>
                                    await goForward({ formikStore })
                                }
                                disabled={isEnrollButtonLoading}
                                variant="secondary"
                                className="flex items-center"
                            >
                                {t("maybeLater")}
                            </Button>
                            <Button
                                onClick={() => {
                                    onEnrollMerchant();
                                    onModalClosed();
                                }}
                                isLoading={isEnrollButtonLoading}
                                disabled={isEnrollButtonLoading || !isAccepted}
                                className="flex items-center"
                            >
                                {t("fasterFundingEnrollButton")}
                            </Button>
                        </div>
                    </div>
                </Modal>
            ) : (
                <Modal
                    isOpen={shouldShowSkipModal}
                    onClose={onModalClosed}
                    className="tablet:max-w-[500px] absolute bottom-0 tablet:static w-full"
                >
                    <ModalHeader onClose={onModalClosed}>
                        <span className="text-2xl">
                            {t("fasterFundingModalHeader")}
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        {t("fasterFundingModalContent")}
                        <div className="mb-[-1rem]">
                            <EnrollmentCheckbox />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="tonal"
                            onClick={async () =>
                                await goForward({ formikStore })
                            }
                            disabled={isEnrollButtonLoading}
                        >
                            {t("maybeLater")}
                        </Button>
                        <Button
                            onClick={() => {
                                onEnrollMerchant();
                                onModalClosed();
                            }}
                            isLoading={isEnrollButtonLoading}
                            disabled={isEnrollButtonLoading || !isAccepted}
                        >
                            {t("fasterFundingEnrollButton")}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    );
};
