import { TableSortDirection } from "@spotoninc/nexus-react";
import { TFunction } from "i18next";

import { Namespace } from "i18nConfig";

import { ILineProduct } from "./PlatformView.types";
import { Sorting } from "./types";

export const attachTotal =
    (t: TFunction<Namespace, undefined, Namespace>) => (label: string) =>
        `${label} (${t("total")})`;

export const calculateSum = <T extends keyof ILineProduct>(
    data: ILineProduct[],
    key: T,
) => data.reduce((acc, item) => acc + Number(item[key]), 0);

export const sortColumn =
    (sort: Sorting) => (a: ILineProduct, b: ILineProduct) => {
        const aValue = a[sort.key as keyof ILineProduct] ?? 0;
        const bValue = b[sort.key as keyof ILineProduct] ?? 0;
        if (aValue < bValue) {
            return sort.direction === TableSortDirection.ascending ? -1 : 1;
        }
        if (aValue > bValue) {
            return sort.direction === TableSortDirection.ascending ? 1 : -1;
        }
        return 0;
    };
