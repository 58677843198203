import { TableSortDirection } from "@spotoninc/nexus-react";

import { TranslationKeys } from "i18nConfig";

import { ILineProduct } from "./PlatformView.types";

export type Keys = keyof ILineProduct & string;

export type FormatFunction<T extends Keys> = (
    value: ILineProduct[T],
) => string | number;

export type Column<T extends Keys> = {
    key: T;
    label: TranslationKeys & string;
    format?: FormatFunction<T>;
    doCalculateSum?: ILineProduct[T] extends number ? boolean : never;
    ignoreOnMobile?: boolean;
};

export type TypedColumns = {
    [K in Keys]: Column<K>;
}[Keys][];

export type Sorting = {
    key?: string;
    direction?: TableSortDirection;
};

export enum QuoteSections {
    HARDWARE = "hardware",
    IMPLEMENTATION = "implementation",
    SOFTWARE = "software",
}
