import React, { useMemo, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderSortCell,
    TableRow,
    TableSortDirection,
} from "@spotoninc/nexus-react";
import clsx from "clsx";

import { useTranslation } from "i18nConfig";

import { calculateSum, sortColumn } from "../helpers";
import { ILineProduct } from "../PlatformView.types";
import { FormatFunction, QuoteSections, Sorting, TypedColumns } from "../types";

type TableViewProps = {
    data: ILineProduct[];
    title: string;
    columns: TypedColumns;
    id: QuoteSections;
};

export const TableView = (props: TableViewProps) => {
    const { data, title, columns, id } = props;
    const [sort, setSort] = useState<Sorting>({});
    const { t } = useTranslation();

    const items = useMemo(() => {
        if (!sort.key) {
            return data;
        }
        return data.toSorted(sortColumn(sort));
    }, [sort]);

    return (
        <div id={id}>
            <div className="w-full flex justify-between py-4 font-semibold text-xl">
                <h3>{title}</h3>
            </div>
            <Table className="w-full table-fixed">
                <TableHead>
                    <TableRow variant="filled" className="h-[4.5rem]">
                        {columns.map(({ key, label }, i) => (
                            <TableHeaderSortCell
                                textAlign="right"
                                className={clsx(
                                    {
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        "[&>button]:!ml-auto": i !== 0,
                                    },
                                    "sticky top-0 [&>button>svg]:shrink-0 [&>button]:!items-center",
                                )}
                                key={key}
                                direction={
                                    sort.key === key
                                        ? sort.direction
                                        : undefined
                                }
                                onSort={() =>
                                    setSort({
                                        key,
                                        direction:
                                            sort.direction ===
                                            TableSortDirection.ascending
                                                ? TableSortDirection.descending
                                                : TableSortDirection.ascending,
                                    })
                                }
                            >
                                <span className="text-left w-fit">
                                    {t(label)}
                                </span>
                            </TableHeaderSortCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, i) => (
                        <TableRow
                            variant="bottom-divider"
                            // @todo replace the index with valid key
                            key={`${item.name}-${i}`}
                        >
                            {columns.map(({ key, format }, i) => {
                                const value = item[key];
                                return (
                                    <TableCell
                                        key={key}
                                        textAlign={i === 0 ? "left" : "right"}
                                    >
                                        {(
                                            format as FormatFunction<typeof key>
                                        )?.(value) ?? value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
                <tfoot>
                    <tr>
                        {columns.map(({ key, doCalculateSum, format }, i) => (
                            <td
                                key={key}
                                className={clsx("font-semibold capitalize", {
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    "text-right": i !== 0,
                                })}
                            >
                                {i === 0
                                    ? t("total")
                                    : doCalculateSum &&
                                      format?.(calculateSum(items, key))}
                            </td>
                        ))}
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};
