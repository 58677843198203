import React, { useState } from "react";
import { Button } from "@spotoninc/nexus-react";

import { API_ENDPOINTS, httpCore, redirectToWelcomeSetup } from "features";
import { Redirect } from "features/store";
import { useTranslation } from "i18nConfig";

export const WelcomeSetupRedirectBox = ({
    isRestaurant,
}: {
    isRestaurant?: boolean;
}) => {
    const { t } = useTranslation();
    const [isRedirectLoading, setIsRedirectLoading] = useState(false);
    const onRedirect = async () => {
        setIsRedirectLoading(true);
        try {
            const { data } = await httpCore.post<Redirect>(
                API_ENDPOINTS.signup.SuccessToWelcomeSetup,
            );
            await redirectToWelcomeSetup(data);
        } finally {
            setIsRedirectLoading(false);
        }
    };

    return (
        <div className="p-6 flex flex-col gap-4 rounded-2xl bg-container-neutral-lightest">
            <div className="flex flex-col gap-2">
                {isRestaurant && (
                    <span className="font-semibold text-xl leading-[24px]">
                        {t("redirectToWelcomeSetupRestaurantTitle")}
                    </span>
                )}
                <span className="tablet:text-lg">
                    {isRestaurant
                        ? t("redirectToWelcomeSetupRestaurantDescription")
                        : t("redirectToWelcomeSetupRetailDescription")}
                </span>
            </div>
            <Button
                isLoading={isRedirectLoading}
                onClick={onRedirect}
                className="w-fit"
            >
                {t("getStartedButton")}
            </Button>
        </div>
    );
};
