import { AppStore, FormikStore, IGenericStep } from "features/store";
import { TranslationKeys } from "i18nConfig";

export enum StepName {
    Quote = "platform",
    Business = "business_general",
    BusinessLegal = "business_legal",
    Shipping = "shipping",
    Applicant = "owners_applicant",
    Owners = "owners_additional",
    BillingForm = "billing_form",
    BillingSuccess = "billing_success",
    BillingSelector = "billing_selector",
    FasterFunding = "faster_funding",
    Signature = "signup",
}

export enum TermsAndConditionsSubmition {
    SubmitTerms = "submitTerms",
}

export enum InactiveStepName {}

export enum StepStatus {
    Incomplete = "incomplete",
    Complete = "complete",
    Error = "error",
    Skipped = "skipped",
}

export type Step = {
    status: StepStatus;
    name: StepName;
    stepOrder: number;
};

export type SubStep = {
    beforeParentStep: boolean;
};

export type StepView = {
    titleTranslationKey: TranslationKeys;
    title?: string;
    route: string;
    subStep?: SubStep;
    error?: string;
    onClick?: (stepName: StepName) => void;
    onSubmit?: ({
        appStore,
        formikStore,
    }: {
        appStore: AppStore;
        formikStore: FormikStore;
    }) => void;
} & Step;

export interface IStepper {
    availableSteps: StepName[];
    currentStep: StepName;
    nextStep: StepName;
    previousStep: StepName;
    steps: IGenericStep[];
}
