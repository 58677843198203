import React, { ReactNode } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { Unstable_LoadingOverlay as LoadingOverlay } from "@spotoninc/nexus-react";

type LoaderFallbackProps = {
    children: ReactNode;
    promiseKey: string;
};

export const LoaderFallback = (props: LoaderFallbackProps) => {
    const { children, promiseKey } = props;
    const data = useLoaderData() as { [key: string]: unknown };

    return (
        <React.Suspense
            fallback={<LoadingOverlay className="[&>div]:bg-white" />}
        >
            <Await resolve={data ? data[promiseKey] : null}>{children}</Await>
        </React.Suspense>
    );
};
