import React, { useState } from "react";
import { BodyText, GhostButton, LabelText } from "@spotoninc/nexus-react";

import { IPageComponentProps } from "app/types";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { ConfirmModal, StepName, useStepNavigate } from "features";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";
import { ImageElement } from "./ImageElement";

import styles from "./BillingSuccessPage.module.scss";

const ItemValue = ({ label, value }: { label: string; value?: string }) => (
    <div className="flex flex-col gap-1">
        <LabelText className="text-neutral-60" size="small">
            {label}
        </LabelText>
        <BodyText>{value}</BodyText>
    </div>
);

export const BillingSuccessPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const [shouldShowRemoveAccountModal, setShouldShowRemoveAccountModal] =
        useState<boolean>(false);
    const { goToTheActiveStep } = useStepNavigate({ appStore });

    const [getState] = useDerivedState(
        appStore,
        ({
            loading: {
                isGetLinkedAccountLoading,
                isDeleteLinkedAccountLoading,
                isGetLinkTokenLoading,
                isSetManualAccountLoading,
                isSetStepLoading,
            },
            data: {
                billingStep: { account },
            },
        }) => ({
            isLoading:
                isGetLinkedAccountLoading ||
                isDeleteLinkedAccountLoading ||
                isGetLinkTokenLoading ||
                isSetManualAccountLoading ||
                isSetStepLoading,
            account,
        }),
    );

    const onClose = () => setShouldShowRemoveAccountModal(false);

    const { isLoading, account } = getState();
    const deleteLinkedAccount = async () => {
        await appStore.asyncDispatch.deleteLinkedAccount();
        onClose();
        goToTheActiveStep({ shouldReplace: true });
    };

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="billing-step"
            isLoading={isLoading}
            title={t("successPageTitle")}
            subtitle={
                <BodyText
                    size="small"
                    className={styles.BillingView_sectionDescription}
                >
                    {t("successPageSubtitle")}
                </BodyText>
            }
            formikStore={formikStore}
            width="narrow"
        >
            <div className="flex gap-6 flex-col pt-3">
                <div className={styles.BillingView_linkedAccountWrapper}>
                    <ConfirmModal
                        isOpen={shouldShowRemoveAccountModal}
                        onClose={onClose}
                        onAccept={deleteLinkedAccount}
                        cancelLabel={t("cancelButtonLabel")}
                        acceptLabel={t("deleteButtonLabel")}
                        title={t("removeAccountModalTitle")}
                        description={t("removeAccountModalDescription")}
                        acceptButtonClassName="!bg-danger-danger-80"
                    />
                    <div className={styles.BillingView_linkedAccountItem}>
                        <div className="rounded-2xl bg-container-neutral-lightest p-6 flex flex-col gap-6 w-full relative overflow-hidden">
                            <div className="grid grid-cols-1 tablet:grid-cols-2 grid-rows-2 gap-8 w-fit">
                                <ItemValue
                                    value={account?.routingNumber}
                                    label={t("routingNumberLabel")}
                                />
                                <ItemValue
                                    value={`•••• •••• •••• ${account?.mask}`}
                                    label={t("accountNumberLabel")}
                                />
                                <ItemValue
                                    value={account?.accountName}
                                    label={t("businessNameOnAccount")}
                                />
                                <ItemValue
                                    value={account?.bankName}
                                    label={t("bankNameLabel")}
                                />
                            </div>
                            <div className="w-full">
                                <GhostButton
                                    destructive
                                    {...getAnalyticProps(
                                        AnalyticElements.DeleteLinkedAccountButton,
                                        { step: StepName.BillingSuccess },
                                    )}
                                    className="font-[500]"
                                    onClick={() =>
                                        setShouldShowRemoveAccountModal(true)
                                    }
                                >
                                    {t("deleteButtonLabel")}
                                </GhostButton>
                            </div>
                            <div className="hidden tablet:flex absolute bottom-0 right-4">
                                <ImageElement />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StepPageLayout>
    );
};
