import React from "react";
import { BodyText } from "@spotoninc/nexus-react";

import { IPageComponentProps } from "app/types";
import { useStepNavigate } from "features";
import { BillingSelectorRoot } from "features/common";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

import styles from "./BillingSelectorPage.module.scss";

export const BillingSelectorPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const [getState] = useDerivedState(
        appStore,
        ({
            loading: {
                isUploadCapturePhotoLoading,
                isSubmitAndGoForwardLoading,
                // isGetLinkedAccountLoading,
                isGetManualAccountDataLoading,
            },
            data: { billingStep },
        }) => ({
            billingStep,
            isManualDataLoading:
                isGetManualAccountDataLoading || isSubmitAndGoForwardLoading,
            isLoading: isUploadCapturePhotoLoading,
            // || isGetLinkedAccountLoading
        }),
    );
    const { isLoading } = getState();
    const { goForward } = useStepNavigate({ appStore });

    const onConnected = async () => {
        await goForward({ formikStore });
    };

    return (
        <StepPageLayout
            appStore={appStore}
            className={styles.ContentWrapper}
            id="billing-step"
            title={t("billingDepositPageTitle")}
            subtitle={
                <BodyText size="small" className="text-left">
                    {t("uploadVoidedCheckDescription")}
                </BodyText>
            }
            formikStore={formikStore}
            width="narrow"
            isLoading={isLoading}
        >
            <BillingSelectorRoot
                appStore={appStore}
                formikStore={formikStore}
                open={open}
                // isPlaidReady={isReady}
                onManualEnter={onConnected}
            />
        </StepPageLayout>
    );
};
