import React from "react";
import { BodyText } from "@spotoninc/nexus-react";

import { getValueWithSpacing, ILegalForm } from "features/application-wizard";

import styles from "../../BusinessInformationForm/BusinessInformationForm.module.scss";

interface ISubLabelIrs {
    legalForm: ILegalForm;
    isProprietor: boolean;
}

export const SubLabelIrs = (props: ISubLabelIrs) => {
    const { legalForm, isProprietor } = props;

    const {
        businessName,
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zip,
    } = legalForm;

    const address = [address1, address2, city, state, zip]
        .filter(Boolean)
        .join(", ");

    if (address) {
        return (
            <BodyText
                size="small"
                className={styles.BusinessInformationForm_sameAddress}
            >
                <strong>
                    {getValueWithSpacing(
                        isProprietor ? [firstName, lastName] : [businessName],
                    )}
                </strong>
                {address}
            </BodyText>
        );
    } else {
        return null;
    }
};
