import React from "react";
import { Spinner } from "@spotoninc/nexus-react";

export const BlurredLoader = () => {
    return (
        <>
            <div className="absolute w-full h-full backdrop-blur z-30" />
            <div className="absolute w-full h-full flex items-center justify-center z-30">
                <Spinner className="h-8" />
            </div>
        </>
    );
};
