import { TFunction } from "i18next";
import * as Yup from "yup";

import { Namespace } from "i18nConfig";

import { IManualLinkAccountFormData } from "./ManualLinkAccountForm.types";

export const manualLinkValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
) =>
    Yup.object().shape({
        routingNumber: Yup.string()
            .trim()
            .required(t("requiredRoutingNumberValidationMessage"))
            .length(9, t("invalidRoutingValidationMessage")),
        accountNumber: Yup.string()
            .trim()
            .required(t("requiredAccountNumberValidationMessage"))
            .min(6, t("invalidAccountNumberValidationMessage"))
            .max(17, t("invalidAccountNumberValidationMessage")),
        accountName: Yup.string()
            .trim()
            .required(t("requiredBusinessNameOnAccountValidationMessage")),
        bankName: Yup.string()
            .trim()
            .required(t("requiredBankNameValidationMessage"))
            .max(200, t("validationLength", { length: 200 })),
    }) satisfies Yup.Schema<IManualLinkAccountFormData>;

export const manualLinkAccountFormikInitialData: IManualLinkAccountFormData = {
    routingNumber: "",
    accountNumber: "",
    accountName: "",
    bankName: "",
};
