import { AppStore, IGenericStep } from "features/store";

export interface ILinkedAccount {
    mask: string;
    accountName: string;
    bankName: string;
    routingNumber: string;
}

export enum LinkingType {
    Manual = "manual",
    Plaid = "plaid",
}

export interface IBillingStep extends IGenericStep {
    account?: ILinkedAccount;
    billingSelector: {
        linkingType?: LinkingType;
        [LinkingType.Manual]: {
            uploadedCheck?: File;
        };
        [LinkingType.Plaid]: {
            linkToken: string;
            token: string;
        };
    };
    billingForm: {
        fields: {
            routingNumber: string;
            accountNumber: string;
            accountName: string;
            bankName: string;
        };
        voidedCheckFile?: File | null;
    };
}

export interface IBillingViewProps {
    setShouldShowRemoveAccountModal: (value: boolean) => void;
    appStore: AppStore;
}
