/* eslint-disable react/prop-types */
import React from "react";
import { createPortal } from "react-dom";
import {
    BodyText,
    Button,
    Unstable_LoadingOverlay as LoadingOverlay,
} from "@spotoninc/nexus-react";
import clsx from "clsx";
import { Icon } from "spoton-lib";

import { Footer, StatelessFooter } from "app/components/Footer";
import { SubHeader } from "app/components/SubHeader";
import { AnalyticElements, AnalyticEvents, getAnalyticProps } from "app/utils";
import { useSendEventHeap, useStepNavigate } from "features";
import { ErrorMessage } from "features/common";

import {
    StatelessStepPageLayoutProps,
    StepPageLayoutProps,
    StepPageWidth,
} from "./StepPageLayout.types";

import styles from "./StepPageLayout.module.scss";

export const StepPageLayout = (props: StepPageLayoutProps) => {
    const {
        appStore,
        className,
        children,
        title,
        subtitle,
        isLoading,
        error,
        customGoBackButton,
        modeOnLoading = "unmount",
        customPrimaryFooterButton,
        customSecondaryFooterButton,
        customFooterCaption,
        isFooterHidden,
        formikStore,
        width = "narrow",
        footerClassName,
        isGoBackHidden,
        customTitleIcon,
        customTitleStyles,
        ...restProps
    } = props;
    const { goBack, canGoBack, activeStepName } = useStepNavigate({ appStore });
    const {
        onClick: onClickCustomButton,
        className: customGoBackClassName,
        ...restCustomButtonProps
    } = customGoBackButton || {};
    const onGoBack = () => {
        if (onClickCustomButton) {
            onClickCustomButton(goBack);
        } else {
            goBack();
        }
    };

    const resolveWidth = (width: StepPageWidth) => {
        switch (width) {
            case "narrow":
                return styles.NarrowerPage;
            case "medium":
                return styles.MediumPage;
            case "wide":
                return styles.WiderPage;
        }
    };

    useSendEventHeap(AnalyticEvents.StepView, { step: activeStepName });

    return (
        <div
            className={clsx(className, styles.StepPageLayout, {
                withoutFooter: isFooterHidden,
            })}
        >
            {isLoading &&
                createPortal(
                    <div className="h-screen absolute w-full top-0 flex items-center">
                        <LoadingOverlay
                            className={clsx(styles.Loader, "[&>div]:bg-white")}
                        />
                    </div>,
                    document.body,
                )}
            {((isLoading && modeOnLoading === "hide") || !isLoading) && (
                <div
                    {...restProps}
                    className={clsx(
                        "flex flex-col w-full h-full",
                        {
                            hidden: isLoading && modeOnLoading === "hide",
                        },
                        "StepPageLayout",
                    )}
                >
                    {createPortal(
                        <div className={styles.MobileBackButtonContainer}>
                            {!isGoBackHidden && canGoBack && (
                                <Button
                                    className={clsx(
                                        styles.MobileBackButtonContainer_backButton,
                                        customGoBackClassName,
                                    )}
                                    variant="tertiary"
                                    data-testid="back-btn"
                                    type="button"
                                    onClick={onGoBack}
                                    {...restCustomButtonProps}
                                    {...getAnalyticProps(
                                        AnalyticElements.GoBackButton,
                                        {
                                            context: "stepPageLayout",
                                            step: activeStepName,
                                        },
                                    )}
                                >
                                    <Icon
                                        className={styles.BackIcon}
                                        name="ArrowLeftIcon"
                                        data-testid="back-btn-icon-header"
                                        size={24}
                                    />
                                </Button>
                            )}
                        </div>,
                        document.body,
                    )}
                    <div
                        className={clsx(
                            "StepContentContainer",
                            styles.StepContentContainer,
                            resolveWidth(width),
                        )}
                    >
                        <div
                            className={clsx("StepWrapper", styles.StepWrapper)}
                        >
                            <SubHeader
                                appStore={appStore}
                                isGoBackHidden={isGoBackHidden}
                                customGoBackButtonProps={{
                                    ...customGoBackButton,
                                    onClick: onGoBack,
                                }}
                            />
                            <div
                                className={clsx(
                                    "TitlesAndContent",
                                    styles.TitlesAndContent,
                                )}
                            >
                                <div className={clsx("Titles", styles.Titles)}>
                                    {customTitleIcon ? (
                                        <div className="flex flex-wrap gap-4 justify-between items-center">
                                            <h4
                                                className={clsx(
                                                    styles.Title,
                                                    customTitleStyles,
                                                )}
                                            >
                                                {title}
                                            </h4>
                                            <div className="min-w-10 h-10 rounded-full cursor-pointer flex items-center justify-center bg-icon-background hover:bg-icon-hover-background">
                                                {customTitleIcon}
                                            </div>
                                        </div>
                                    ) : (
                                        <h4
                                            className={clsx(
                                                styles.Title,
                                                customTitleStyles,
                                            )}
                                        >
                                            {title}
                                        </h4>
                                    )}

                                    {subtitle &&
                                        typeof subtitle === "string" && (
                                            <BodyText
                                                size="small"
                                                className={clsx(
                                                    styles.Subtitle,
                                                    "!text-base",
                                                )}
                                            >
                                                {subtitle}
                                            </BodyText>
                                        )}
                                    {subtitle &&
                                        typeof subtitle !== "string" &&
                                        subtitle}
                                </div>
                                {error ? (
                                    <div className={styles.ErrorContainer}>
                                        {/*Design to be discussed */}
                                        <ErrorMessage message="oopsErrorText" />
                                    </div>
                                ) : (
                                    <div
                                        className={clsx(
                                            "StepContent",
                                            styles.StepContent,
                                        )}
                                    >
                                        {children}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isFooterHidden && (
                        <div
                            className={clsx(
                                resolveWidth(width),
                                styles.StepFooterContainer,
                                footerClassName,
                                "StepFooterContainer",
                            )}
                        >
                            <Footer
                                appStore={appStore}
                                formikStore={formikStore}
                                customPrimaryButton={customPrimaryFooterButton}
                                customSecondaryButton={
                                    customSecondaryFooterButton
                                }
                                customBottomCaption={customFooterCaption}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export const StatelessStepPageLayout = (
    props: StatelessStepPageLayoutProps,
) => {
    const {
        className,
        children,
        title,
        subtitle,
        isLoading,
        error,
        modeOnLoading = "unmount",
        customPrimaryFooterButton,
        customSecondaryFooterButton,
        isFooterHidden,
        width = "narrow",
        footerClassName,
        customTitleIcon,
        ...restProps
    } = props;

    const resolveWidth = (width: StepPageWidth) => {
        switch (width) {
            case "narrow":
                return styles.NarrowerPage;
            case "medium":
                return styles.MediumPage;
            case "wide":
                return styles.WiderPage;
        }
    };

    return (
        <div
            className={clsx(className, styles.StepPageLayout, {
                withoutFooter: isFooterHidden,
            })}
        >
            {isLoading &&
                createPortal(
                    <div className="h-screen absolute w-full top-0 flex items-center">
                        <LoadingOverlay
                            className={clsx(styles.Loader, "[&>div]:bg-white")}
                        />
                    </div>,
                    document.body,
                )}
            {((isLoading && modeOnLoading === "hide") || !isLoading) && (
                <div
                    {...restProps}
                    className={clsx(
                        "flex flex-col w-full",
                        {
                            hidden: isLoading && modeOnLoading === "hide",
                        },
                        "StepPageLayout",
                    )}
                >
                    <div
                        className={clsx(
                            "StepContentContainer",
                            styles.StepContentContainer,
                            resolveWidth(width),
                        )}
                    >
                        <div
                            className={clsx("StepWrapper", styles.StepWrapper)}
                        >
                            <div
                                className={clsx(
                                    "TitlesAndContent",
                                    styles.TitlesAndContent,
                                )}
                            >
                                <div className={clsx("Titles", styles.Titles)}>
                                    {customTitleIcon ? (
                                        <div className="flex justify-between items-center">
                                            <h4 className={styles.Title}>
                                                {title}
                                            </h4>
                                            <div className="w-10 h-10 rounded-full cursor-pointer flex items-center justify-center bg-icon-background hover:bg-icon-hover-background">
                                                {customTitleIcon}
                                            </div>
                                        </div>
                                    ) : (
                                        title && (
                                            <h4 className={styles.Title}>
                                                {title}
                                            </h4>
                                        )
                                    )}

                                    {subtitle &&
                                        typeof subtitle === "string" && (
                                            <BodyText
                                                size="small"
                                                className={styles.Subtitle}
                                            >
                                                {subtitle}
                                            </BodyText>
                                        )}
                                    {subtitle &&
                                        typeof subtitle !== "string" &&
                                        subtitle}
                                </div>
                                {error ? (
                                    <div className={styles.ErrorContainer}>
                                        {/*Design to be discussed */}
                                        <ErrorMessage message="oopsErrorText" />
                                    </div>
                                ) : (
                                    <div
                                        className={clsx(
                                            "StepContent",
                                            styles.StepContent,
                                        )}
                                    >
                                        {children}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isFooterHidden && (
                        <div
                            className={clsx(
                                resolveWidth(width),
                                styles.StepFooterContainer,
                                footerClassName,
                                "StepFooterContainer",
                            )}
                        >
                            <StatelessFooter
                                customPrimaryButton={customPrimaryFooterButton}
                                customSecondaryButton={
                                    customSecondaryFooterButton
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
