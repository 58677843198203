import React from "react";
import { BodyText } from "@spotoninc/nexus-react";
import { ErrorMessage } from "formik";
import { t } from "i18next";

import { IFormField } from "features/common/types";

import { RadioField } from "../RadioField";
import { YesNoAnswer } from "./YesNoRadioField.types";

export const YesNoRadioField = <T extends Record<string, any>>({
    formik,
    name,
    label,
}: IFormField<T>) => (
    <div className="flex flex-col text-left gap-2">
        <BodyText size="medium" className="font-normal">
            {label}
        </BodyText>
        <div className="flex gap-6">
            <RadioField
                formik={formik}
                name={name}
                className="w-[160px]"
                label={<BodyText size="small">{t("yes")}</BodyText>}
                checked={formik.values[name] === YesNoAnswer.Yes}
                value={YesNoAnswer.Yes}
                onChange={() => formik.setFieldError(name, undefined)}
            />
            <RadioField
                formik={formik}
                name={name}
                className="w-[160px]"
                label={<BodyText size="small">{t("no")}</BodyText>}
                checked={formik.values[name] === YesNoAnswer.No}
                value={YesNoAnswer.No}
                onChange={() => formik.setFieldError(name, undefined)}
            />
        </div>
        <ErrorMessage name={name}>
            {(msg) => <div className="text-red-500 text-sm">{msg}</div>}
        </ErrorMessage>
    </div>
);
