import React from "react";
import {
    BodyText,
    Modal,
    ModalBody,
    ModalHeader,
} from "@spotoninc/nexus-react";
import { Icon } from "spoton-lib";

import { useTranslation } from "i18nConfig";

import styles from "./ResponsiblePartyInfoModal.module.scss";

interface IResponsiblePartyInfoModalProps {
    shouldShowResponsiblePartyInfoModal: boolean;
    onClose: () => void;
}

export const ResponsiblePartyInfoModal = ({
    shouldShowResponsiblePartyInfoModal,
    onClose,
}: IResponsiblePartyInfoModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={shouldShowResponsiblePartyInfoModal}
            onClose={onClose}
            className="tablet:max-w-[500px] absolute bottom-0 tablet:static w-full"
        >
            <ModalHeader onClose={onClose}>
                <div className={styles.ResponsiblePartyInfoModal_headerWrapper}>
                    <div
                        className={styles.ResponsiblePartyInfoModal_headerTitle}
                    >
                        {t("responsiblePartyInfoModalTitle")}
                    </div>
                    <Icon name="InformativeIcon" size={27} />
                </div>
            </ModalHeader>
            <ModalBody>
                <BodyText size="small" className="text-base-base-90">
                    {t("responsiblePartyTooltip")}
                </BodyText>
            </ModalBody>
        </Modal>
    );
};
