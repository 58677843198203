import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";

import { BillingInfo, Informative } from "app/icons";
import { Breakpoint, ScrollToFieldError, useBreakpoint } from "features";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepName } from "../Stepper";
import { TextField } from "../TextField";
import {
    IManualLinkAccountForm,
    IManualLinkAccountFormData,
} from "./ManualLinkAccountForm.types";
import {
    manualLinkAccountFormikInitialData,
    manualLinkValidationSchema,
} from "./ManualLinkAccountForm.utils";
import { ManualLinkInfoModal } from "./ManualLinkInfoModal";

import styles from "./ManualLinkAccountForm.module.scss";

enum ModalVariant {
    Routing,
    Account,
}

export const ManualLinkAccountForm = ({
    formikStore,
    onFormSubmit,
    appStore,
}: IManualLinkAccountForm) => {
    const { t } = useTranslation();
    const ref = useRef<FormikProps<IManualLinkAccountFormData> | null>(null);
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                billingStep: {
                    billingForm: { fields },
                },
            },
        }) => {
            return {
                fields,
            };
        },
    );
    const { fields } = getState();

    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    const [shouldShowAccountInfoModal, setShouldShowAccountInfoModal] =
        useState<boolean>(false);

    const [modalVariant, setModelVariant] = useState<ModalVariant>(
        ModalVariant.Routing,
    );

    useEffect(() => {
        formikStore.dispatch.addRef(StepName.BillingForm, ref);
        return () => {
            formikStore.dispatch.removeRef(StepName.BillingForm);
        };
    }, []);

    const onNumberChange = <T,>(
        e: React.ChangeEvent<HTMLInputElement>,
        field: T extends Record<string, unknown> ? keyof T & string : string,
        pattern: RegExp,
    ) => {
        const value = e.target.value;
        if (value === "" || pattern.test(value)) {
            ref.current?.setFieldValue(field, value);
        } else {
            ref.current?.setFieldValue(
                field,
                ref.current?.values[field as keyof IManualLinkAccountFormData],
            );
        }
    };

    const ModalInfo = () => {
        return (
            <div className="absolute top-16 right-[-150px] w-[310px] rounded-lg bg-white shadow-lg z-50">
                <div className="relative h-40">
                    <BillingInfo className="absolute inset-0 h-full w-full rounded-tr-xl" />
                    <button
                        className="absolute top-1 right-2 text-neutral-60 rounded-full text-md p-2"
                        onClick={() => setShouldShowAccountInfoModal(false)}
                    >
                        ✕
                    </button>
                </div>

                <div className="p-4">
                    <p className="text-sm text-gray-700 text-left text-neutral-90 m-0">
                        {t("manualLinkAccountModalDescription")}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div
            className={styles.ManualLinkAccountModal}
            id="manual-link-account-modal"
        >
            <div className={styles.ManualLinkAccountModal_content}>
                <Formik
                    innerRef={ref}
                    initialValues={{
                        ...manualLinkAccountFormikInitialData,
                        ...fields,
                    }}
                    validationSchema={() => manualLinkValidationSchema(t)}
                    onSubmit={async (values) => {
                        await onFormSubmit(values);
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <ScrollToFieldError formik={formik} />
                                <div className="flex flex-col gap-6 md:gap-[13px] pb-2 pt-7">
                                    <div className="flex flex-col tablet:flex-row gap-6">
                                        <div className="flex relative flex-start w-full md:w-1/2">
                                            <TextField
                                                dataDdPrivacy="mask-user-input"
                                                trim="start"
                                                className="flex-1 text-sm py-0"
                                                formik={formik}
                                                name="routingNumber"
                                                label={t("routingNumberLabel")}
                                                value={
                                                    formik.values.routingNumber
                                                }
                                                placeholder={t(
                                                    "routingNumberPlaceholder",
                                                )}
                                                onChange={(e) =>
                                                    onNumberChange<IManualLinkAccountFormData>(
                                                        e,
                                                        "routingNumber",
                                                        /^\d{0,9}$/,
                                                    )
                                                }
                                                inputMode="numeric"
                                            />
                                            <>
                                                {isLowerThanTablet ? (
                                                    <div className="absolute left-[120px] top-[1px]">
                                                        <div className="relative">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setShouldShowAccountInfoModal(
                                                                        true,
                                                                    );
                                                                    setModelVariant(
                                                                        ModalVariant.Routing,
                                                                    );
                                                                }}
                                                            >
                                                                <Informative />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="absolute left-[118px] top-[1px]">
                                                        <div className="relative group">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setShouldShowAccountInfoModal(
                                                                        true,
                                                                    );
                                                                    setModelVariant(
                                                                        ModalVariant.Routing,
                                                                    );
                                                                }}
                                                            >
                                                                <Informative />
                                                            </button>
                                                            {shouldShowAccountInfoModal &&
                                                                modalVariant ===
                                                                    ModalVariant.Routing && (
                                                                    <ModalInfo />
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                        <div className="flex relative items-center flex-start w-full md:w-1/2">
                                            <TextField
                                                dataDdPrivacy="mask-user-input"
                                                trim="start"
                                                className="flex-1 text-sm [&_input]:pr-2  py-0"
                                                formik={formik}
                                                name="accountNumber"
                                                label={t("accountNumberLabel")}
                                                value={
                                                    formik.values.accountNumber
                                                }
                                                placeholder={t(
                                                    "accountNumberPlaceholder",
                                                )}
                                                onChange={(e) =>
                                                    onNumberChange<IManualLinkAccountFormData>(
                                                        e,
                                                        "accountNumber",
                                                        /^\d{0,17}$/,
                                                    )
                                                }
                                                inputMode="numeric"
                                            />
                                            <>
                                                {isLowerThanTablet ? (
                                                    <div className="absolute left-[124px] top-[1px]">
                                                        <div className="relative">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setShouldShowAccountInfoModal(
                                                                        true,
                                                                    );
                                                                    setModelVariant(
                                                                        ModalVariant.Account,
                                                                    );
                                                                }}
                                                            >
                                                                <Informative />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="absolute left-[124px] top-[1px]">
                                                        <div className="relative group">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setShouldShowAccountInfoModal(
                                                                        true,
                                                                    );
                                                                    setModelVariant(
                                                                        ModalVariant.Account,
                                                                    );
                                                                }}
                                                            >
                                                                <Informative />
                                                            </button>
                                                            {shouldShowAccountInfoModal &&
                                                                modalVariant ===
                                                                    ModalVariant.Account && (
                                                                    <ModalInfo />
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="flex flex-col tablet:flex-row gap-6">
                                        <TextField
                                            dataDdPrivacy="mask-user-input"
                                            trim="start"
                                            className="flex-1 text-sm py-0"
                                            formik={formik}
                                            name="accountName"
                                            label={t("businessNameLabel")}
                                            value={formik.values.accountName}
                                        />
                                        <TextField
                                            dataDdPrivacy="mask-user-input"
                                            trim="start"
                                            className="flex-1 text-sm py-0"
                                            formik={formik}
                                            name="bankName"
                                            label={t("bankNameLabel")}
                                            value={formik.values.bankName}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            {isLowerThanTablet && (
                <ManualLinkInfoModal
                    shouldShowAccountInfoModal={shouldShowAccountInfoModal}
                    setShouldShowAccountInfoModal={
                        setShouldShowAccountInfoModal
                    }
                />
            )}
        </div>
    );
};
