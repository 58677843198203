import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@spotoninc/nexus-react";
import clsx from "clsx";

type CommonModalProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    subtitle?: string;
    children: ReactNode;
    className?: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    isConfirmLoading?: boolean;
    onConfirm: () => Promise<void>;
    isConfirmDisabled?: boolean;
};
export const CommonModal = ({
    isOpen,
    onClose,
    title,
    subtitle,
    children,
    className = "",
    confirmButtonLabel,
    cancelButtonLabel,
    isConfirmLoading = false,
    onConfirm,
    isConfirmDisabled,
}: CommonModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            className={clsx(
                "tablet:w-[520px] w-full max-w-full max-h-[95dvh] flex flex-col overflow-auto bottom-0 absolute tablet:static",
                className,
            )}
        >
            <div className="px-6 pt-4 flex flex-col gap-4">
                {subtitle && <span>{subtitle}</span>}
                <div className="flex flex-col gap-4">{children}</div>
                <div className="flex pt-4 pb-6 gap-3 tablet:justify-end justify-between [&>button]:w-full tablet:[&>button]:w-auto">
                    <Button
                        type="button"
                        variant="secondary"
                        onClick={onClose}
                        className="flex justify-center"
                    >
                        {cancelButtonLabel || t("cancelButtonLabel")}
                    </Button>
                    <Button
                        type="button"
                        className="flex justify-center"
                        isLoading={isConfirmLoading}
                        onClick={async () => {
                            await onConfirm();
                        }}
                        disabled={isConfirmDisabled}
                    >
                        {confirmButtonLabel || t("saveLabel")}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
