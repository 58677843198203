import React, { ReactNode } from "react";
import { BodyText } from "@spotoninc/nexus-react";

type SectionItems = { label: ReactNode; value: ReactNode }[];

type SectionProps = {
    title: string;
    items: SectionItems;
};
export const Section = ({ title, items }: SectionProps) => {
    return (
        <div>
            <div className="px-3 py-2.5 rounded-xl bg-container-neutral-lightest">
                <h3 className="text-sm font-semibold text-neutral-60">
                    {title}
                </h3>
            </div>
            <ul className="px-3">
                {items.map(({ label, value }, i, dt) => (
                    <>
                        <li className="flex w-full justify-between py-4 items-start">
                            <BodyText className="text-neutral-90 !text-sm tablet:!text-base">
                                {label}
                            </BodyText>
                            <span className="whitespace-nowrap font-semibold min-w-[110px] tablet:min-w-[100px] text-right text-sm tablet:text-base">
                                {value}
                            </span>
                        </li>
                        {i !== dt.length - 1 && <hr />}
                    </>
                ))}
            </ul>
        </div>
    );
};
