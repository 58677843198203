import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";

export type THttpError = AxiosError & {
    response: AxiosResponse;
    data: {
        error: string;
        detail: string;
        code: string;
    };
};

/** The hierarchy of keys used for matching
 * an error with appropriate handler.
 *
 * More specific key takes precedence over the more general ones
 */
type KeysHierarchy = [
    errorMessage: string | undefined,
    responseErrorName: string | undefined,
    responseErrorCode: string | undefined,
    errorName: string | undefined,
    status: string | undefined,
    errorMethod: string | undefined,
    all: "all",
];

type IErrorHandlerCallback = (
    error: THttpError,
    axiosInstance?: AxiosInstance,
) => Promise<unknown>;

type IErrorHandlerMany = {
    [key in StatusCodes]?: IErrorHandlerCallback;
} & {
    all?: IErrorHandlerCallback;
} & {
    [key in string]?: IErrorHandlerCallback;
};

export class ErrorHandlerRegistry {
    private _handlers = new Map<string | StatusCodes, IErrorHandlerCallback>();

    private _parent?: ErrorHandlerRegistry;

    constructor(parent?: ErrorHandlerRegistry, input?: IErrorHandlerMany) {
        this._parent = parent;
        if (input) {
            this._registerMany(input);
        }
    }

    register = (key: string, handler: IErrorHandlerCallback) => {
        this._handlers.set(key, handler);
        return this;
    };

    unregister = (key: string) => {
        this._handlers.delete(key);
        return this;
    };

    private _findHandlerByKey = (
        key: string | StatusCodes,
    ): IErrorHandlerCallback | undefined => {
        return this._handlers.get(key) || this._parent?._findHandlerByKey(key);
    };

    private _registerMany = (input: IErrorHandlerMany) => {
        Object.entries(input).forEach(([key, value]) => {
            if (value) {
                this.register(key, value);
            }
        });
        return this;
    };

    private _handleError = async (
        keys: (string | StatusCodes | undefined)[],
        error: THttpError,
        axiosInstance: AxiosInstance,
    ) => {
        const seeker = keys.find(
            (key) => key !== undefined && !!this._findHandlerByKey(key),
        );
        const handler = seeker && this._findHandlerByKey(seeker);
        if (handler) return handler(error, axiosInstance);
    };

    responseErrorHandler =
        (axiosInstance: AxiosInstance) => async (error: THttpError) => {
            if (error === null) throw new Error("Unknown error");
            const response = error?.response;
            const {
                data: { error: responseErrorName, code: responseErrorCode },
            } = response || { data: { error: undefined, code: undefined } };
            const { config, name: errorName, message: errorMessage } = error;
            const { method: errorMethod } = config || { method: undefined };
            const keysHierarchy: KeysHierarchy = [
                errorMessage,
                responseErrorName,
                responseErrorCode,
                errorName,
                String(response?.status),
                errorMethod,
                "all",
            ];
            return this._handleError(keysHierarchy, error, axiosInstance);
        };
}
