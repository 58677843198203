import React, { useRef, useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { GhostButton } from "@spotoninc/nexus-react";

import { useTranslation } from "i18nConfig";

import { IPlatformStep } from "../PlatformView.types";
import { AccordionSection } from "./AccordionSection.component";
import { getSections } from "./sections";

const { Root } = Accordion;

export const AccordionMobile = ({ data }: { data: IPlatformStep }) => {
    const { t } = useTranslation();
    const [openedSections, setOpenedSections] = useState<string[]>([]);
    const allKeysRef = useRef<Record<string, unknown>>({});

    return (
        <div className="w-full flex flex-col justify-start gap-4 pb-20">
            <div className="flex flex-col gap-8 pb-4">
                <Root
                    type="multiple"
                    value={openedSections}
                    onValueChange={setOpenedSections}
                >
                    <div className="flex flex-col pt-4">
                        <GhostButton
                            onClick={() => {
                                setOpenedSections(
                                    Object.keys(allKeysRef.current),
                                );
                            }}
                        >
                            {t("expandAll")}
                        </GhostButton>

                        {getSections(data, t).map((section) => (
                            <AccordionSection
                                key={section.id}
                                {...section}
                                allKeysRef={allKeysRef}
                            />
                        ))}
                    </div>
                </Root>
            </div>
        </div>
    );
};
