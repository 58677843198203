import { TFunction } from "i18next";
import * as Yup from "yup";

import {
    maxLengthValidation,
    validateEmail,
    validatePhone,
    validateZip,
} from "features/common/utils";
import { Namespace } from "i18nConfig/translation.types";

export const shippingInformationValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
    isShippingSameAsBusiness: boolean,
) => {
    const requiredConditionalValidation = Yup.string().when([], {
        is: () => !isShippingSameAsBusiness,
        then: (schema) =>
            schema
                .trim()
                .concat(maxLengthValidation(100, t))
                .required(t("requiredValidationMessage")),
    });

    return Yup.object().shape({
        isShippingSameAsBusiness: Yup.bool().required(),
        shippingAddress: Yup.object().shape({
            address1: requiredConditionalValidation,
            address2: Yup.string()
                .nullable()
                .when([], {
                    is: () => !isShippingSameAsBusiness,
                    then: (schema) =>
                        schema
                            .nullable()
                            .trim()
                            .concat(maxLengthValidation(100, t)),
                }),
            city: requiredConditionalValidation,
            zip: Yup.string().when([], {
                is: () => !isShippingSameAsBusiness,
                then: (schema) =>
                    validateZip(
                        schema,
                        t("invalidZipValidationMessage"),
                        t("requiredValidationMessage"),
                    ),
            }),
            state: requiredConditionalValidation,
            country: requiredConditionalValidation,
        }),
        installationTimeframe: Yup.string().required(),
        installationContact: Yup.object().shape({
            firstName: Yup.string()
                .trim()
                .concat(maxLengthValidation(100, t))
                .required(
                    `${t("firstNameLabel")} ${t(
                        "requiredValidationPrefixMessage",
                    )}`,
                ),
            lastName: Yup.string()
                .trim()
                .concat(maxLengthValidation(100, t))
                .required(
                    `${t("lastNameLabel")} ${t(
                        "requiredValidationPrefixMessage",
                    )}`,
                ),
            email: validateEmail(
                `${t("emailLabel")} ${t("requiredValidationPrefixMessage")}`,
                t,
            ),
            phone: validatePhone(
                t("invalidPhoneNumberValidationMessage"),
                `${t("phoneLabel")} ${t("requiredValidationPrefixMessage")}`,
                false,
            ),
        }),
        salesTaxRate: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .max(20, t("maxTaxValidation", { value: 20 }))
            .required(t("requiredTaxValidation")),
    });
};
