import { TFunction } from "i18next";
import * as Yup from "yup";

import { Namespace } from "i18nConfig/translation.types";
export const validateAddTax = (
    t: TFunction<Namespace, undefined, Namespace>,
) => {
    return Yup.object().shape({
        name: Yup.string()
            .required(t("requiredTaxNameValidation"))
            .trim()
            .min(3, t("minTaxNameValidation", { len: 3 }))
            .max(100, t("maxTaxNameValidation", { len: 100 })),
        rate: Yup.number()
            .typeError(t("requiredTaxValidation"))
            .required(t("requiredTaxValidation"))
            .max(20, t("maxTaxValidation", { value: 20 })),
    });
};
