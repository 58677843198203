import React from "react";
import ReactDOM from "react-dom";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

// import { getConfigVar, getIsProdOrStaging } from "features/common";
import { App } from "app/components";
import {
    EnvironmentName,
    fullstory,
    getConfigVar,
    getEnvironmentName,
    getIsProdOrStaging,
} from "features";

// Global styles
import "./index.css";

// Initialize FullStory integration
fullstory.init();

if (getIsProdOrStaging()) {
    datadogLogs.init({
        clientToken: getConfigVar("REACT_APP_DATADOG_CLIENT_TOKEN") as string,
        env: getConfigVar("REACT_APP_DATADOG_ENV") as string,
        site: "datadoghq.com",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
    });

    const envName = getEnvironmentName();
    let sessionReplaySampleRate;

    switch (envName) {
        case EnvironmentName.PROD:
            sessionReplaySampleRate = 100;
            break;
        case EnvironmentName.STAGING:
            sessionReplaySampleRate = 5;
            break;
        case EnvironmentName.UNLIMITED:
            sessionReplaySampleRate = 100;
            break;
        default:
            sessionReplaySampleRate = 0;
    }

    datadogRum.init({
        applicationId: getConfigVar(
            "REACT_APP_DATADOG_APPLICATION_ID",
        ) as string,
        clientToken: getConfigVar("REACT_APP_DATADOG_CLIENT_TOKEN") as string,
        site: "datadoghq.com",
        service: "merchant-signup-web",
        env: getConfigVar("REACT_APP_DATADOG_ENV") as string,
        sessionSampleRate: 100,
        sessionReplaySampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
        enableExperimentalFeatures: ["clickmap"],
    });
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);
