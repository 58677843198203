import React, { ReactNode } from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronUpIcon } from "@spotoninc/nexus-web-assets";

import { moneyFormat } from "features/utils";

const { Header, Trigger } = Accordion;

type AccordionHeaderProps = {
    total: number;
    label: ReactNode;
    className?: string;
};
export const AccordionHeader = ({
    total,
    label,
    className,
}: AccordionHeaderProps) => (
    <Header className={className}>
        <Trigger className="group w-full flex justify-between text-neutral-90 text-sm">
            <span className="max-w-[200px] text-left">{label}</span>
            <div className="flex items-start gap-2">
                <span className="w-20 text-neutral-90 font-semibold text-right">
                    {moneyFormat(total)}
                </span>
                <ChevronUpIcon className="group-data-[state=open]:rotate-180 transition-transform h-6 w-6 text-neutral-60" />
            </div>
        </Trigger>
    </Header>
);
