import { TFunction } from "i18next";

import { Namespace } from "i18nConfig";

import { hardwareAndImplementationColumns, softwareColumns } from "../columns";
import { IPlatformStep } from "../PlatformView.types";
import { QuoteSections } from "../types";

export const getSections = (
    data: IPlatformStep,
    t: TFunction<Namespace, undefined, Namespace>,
) => {
    return [
        {
            data: data.hardware ?? [],
            columns: hardwareAndImplementationColumns,
            title: t("hardware"),
            id: QuoteSections.HARDWARE,
        },
        {
            data: data.implementation ?? [],
            columns: hardwareAndImplementationColumns,
            title: t("implementation"),
            id: QuoteSections.IMPLEMENTATION,
        },
        {
            data: data.software ?? [],
            columns: softwareColumns,
            title: t("monthlySubscriptionFees"),
            id: QuoteSections.SOFTWARE,
        },
    ];
};
