import React from "react";
import { Button, Modal } from "@spotoninc/nexus-react";

import { BillingInfo } from "app/icons";
import { useTranslation } from "i18nConfig";

interface IManualLinkInfoModalProps {
    shouldShowAccountInfoModal: boolean;
    setShouldShowAccountInfoModal: (value: boolean) => void;
}

export const ManualLinkInfoModal = ({
    shouldShowAccountInfoModal,
    setShouldShowAccountInfoModal,
}: IManualLinkInfoModalProps) => {
    const { t } = useTranslation();

    const onClose = () => setShouldShowAccountInfoModal(false);

    return (
        <Modal
            isOpen={shouldShowAccountInfoModal}
            onClose={onClose}
            className="tablet:max-w-[500px] absolute bottom-0 tablet:static w-full p-0"
        >
            <div className="relative h-40 w-full bg-informative-background rounded-t-xl">
                <BillingInfo className="absolute inset-0 h-full w-full" />
                <button
                    className="absolute top-1 right-2 text-neutral-60 rounded-full text-lg p-2"
                    onClick={() => setShouldShowAccountInfoModal(false)}
                >
                    ✕
                </button>
            </div>
            <div className="p-4 pb-6 pt-2 flex flex-col gap-2">
                <div className="py-4">
                    <p className="text-sm text-gray-700 text-center text-neutral-90 m-0">
                        {t("manualLinkAccountModalDescription")}
                    </p>
                </div>
                <Button
                    className="w-full flex items-center justify-center"
                    onClick={onClose}
                >
                    {t("ok")}
                </Button>
            </div>
        </Modal>
    );
};
