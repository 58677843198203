/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from "react";
import { TextInput } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField } from "formik";
import { Icon } from "spoton-lib";

import { ITextFieldProps } from "./TextField.types";

import styles from "./TextField.module.scss";

interface IPasswordWrapperProps {
    children?: React.ReactNode;
    isHide?: boolean;
    isActive?: boolean;
    isError?: boolean;
    onHide?: () => void;
}

function PasswordWrapper({
    children,
    isHide,
    isActive,
    isError,
    onHide = () => ({}),
}: IPasswordWrapperProps) {
    return (
        <div className={styles.TextField_iconGroup}>
            {children}
            <div className={styles.TextField_passwordIcon} onClick={onHide}>
                <Icon
                    name={isHide ? "VisibilityOffIcon" : "VisibilityOnIcon"}
                    size={24}
                    className={clsx(
                        isError
                            ? "text-danger-danger-70"
                            : isActive
                            ? "text-primary-primary-50"
                            : "text-gray-700",
                    )}
                />
            </div>
        </div>
    );
}

export function TextField<T>(props: ITextFieldProps<T>) {
    const {
        name,
        label,
        className,
        value,
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        isDisabled,
        isClearable = true,
        isHidden,
        errorMessage,
        isPassword,
        type,
        placeholder,
        id,
        trim,
        onKeyDown,
        inputMode,
        autoCapitalize,
        dataDdPrivacy,
    } = props;
    const [isActive, setActive] = useState(false);
    const [isHidePassword, setHidePassword] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const [field, meta, helpers] = useField(name);

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        setActive(true);
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        field.onBlur(e);
        setActive(false);
        onBlur(e);
    };

    const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (trim === "end") {
            e.target.value = e.target.value.trimEnd();
        } else if (trim === "start") {
            e.target.value = e.target.value.trimStart();
        } else if (trim === "both") {
            e.target.value = e.target.value.trim();
        }
        helpers.setValue(e.target.value);
        onChange(e);
    };

    const toggleHidePassword = () => {
        const shouldHide = !isHidePassword;
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.type = shouldHide ? "password" : type || "text";
        }
        setHidePassword(!isHidePassword);
    };

    const input = (
        <TextInput
            {...field}
            id={id || `${name}-field`}
            ref={inputRef}
            value={value}
            name={name}
            onChange={onChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            onKeyDown={onKeyDown}
            className={clsx(className, "text-left [&>span]:break-normal", {
                [styles.TextField_hidden]: isHidden,
            })}
            inputMode={inputMode || "text"}
            label={label}
            errorMessage={meta.touched ? errorMessage ?? meta.error : ""}
            disabled={isDisabled}
            clearable={isClearable && !isPassword}
            type={isPassword && isHidePassword ? "password" : type}
            placeholder={placeholder}
            data-testid={id || name}
            autoCapitalize={autoCapitalize || "on"}
            data-dd-privacy={dataDdPrivacy}
        />
    );

    return isPassword ? (
        <PasswordWrapper
            isActive={isActive}
            isError={meta.touched && !!meta.error}
            isHide={isHidePassword}
            onHide={toggleHidePassword}
        >
            {input}
        </PasswordWrapper>
    ) : (
        input
    );
}

export default TextField;
