import React from "react";

import photoRestaurant1 from "app/images/022223_bar_7527_with_screen.webp";
import photoRestaurant2 from "app/images/022223_casualdining_5399.webp";
import photoRestaurant3 from "app/images/022223_finedining_6531_with_screen.webp";
import photoRetail3 from "app/images/rectangle_139022.webp";
import photoRetail1 from "app/images/rectangle_139916.webp";
import photoRetail2 from "app/images/rectangle_139917.webp";

import styles from "./SuccessImagesStatic.module.scss";

export const SuccessImagesStatic = ({
    isRestaurant,
}: {
    isRestaurant?: boolean;
}) => {
    const [photo1, photo2, photo3] = isRestaurant
        ? [photoRestaurant1, photoRestaurant2, photoRestaurant3]
        : [photoRetail1, photoRetail2, photoRetail3];

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 464 559"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.Wrapper}
        >
            <rect
                width="180"
                height="192"
                rx="16"
                x="0"
                y="367"
                fill="url(#pattern0)"
                className={styles.BottomImage}
            />
            <rect
                x="204"
                y="216"
                width="224"
                height="288"
                rx="16"
                fill="url(#pattern1)"
                className={styles.TopImage}
            />
            <rect
                opacity="0.6"
                x="284"
                width="180"
                height="192"
                rx="16"
                fill="url(#pattern2)"
                className={styles.MidImage}
            />
            <defs>
                <pattern
                    id="pattern0"
                    patternUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <image id="image0" width="180" height="192" href={photo2} />
                </pattern>
                <pattern
                    id="pattern1"
                    patternUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <image id="image1" width="224" height="288" href={photo3} />
                </pattern>
                <pattern
                    id="pattern2"
                    patternUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <image id="image2" width="180" height="192" href={photo1} />
                </pattern>
            </defs>
        </svg>
    );
};
