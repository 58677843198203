import { TFunction } from "i18next";
import moment from "moment";
import * as Yup from "yup";

import { FederalTaxType, OwnershipTypes } from "features/application-wizard";
import { DateFormat } from "features/common";
import { maxLengthValidation, validateZip } from "features/common/utils";
import { Namespace } from "i18nConfig";

export const einRegex =
    /^((?!00)(0[1-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])-\d{7}|\*{2}-\*{3}\d{4}$)$/;
export const itinRegex =
    /^(9[0-9][0-9]-(?!00)(5[0-9]|6[0-5]|7[0-9]|8[0-8]|9[0-2]|9[4-9])-\d{4}|\*{3}-\*{2}-\d{4})$/;
export const ssnRegex =
    /^(?!111-11-1111|333-33-3333|666-66-6666|123-45-6789)((?!000|666|9\d{2})\d{3}-(?!00)(0[1-9]|[1-9][0-9])-(?!0000)\d{4}|\*{3}-\*{2}-\d{4})$/;
// Regex for applicant ssn
export const itinOrSsnRegex = new RegExp(
    itinRegex.source + "|" + ssnRegex.source,
);

export const getLegalBusinessFormValidation = (
    t: TFunction<Namespace, undefined, Namespace>,
    isLegalAddressSame: boolean,
    isLegalIrsAddressSame: boolean,
    federalTaxType: FederalTaxType,
    isProprietor: boolean,
) => {
    const federalTaxIdValidation = Yup.string()
        .required(`${t("einLabel")} ${t("requiredValidationPrefixMessage")}`)
        .when([], {
            is: () => federalTaxType === FederalTaxType.Ein || !federalTaxType,
            then: (schema) =>
                schema
                    .min(
                        10,
                        `${t("einLabel")} ${t("einLabelValidationMessage")}`,
                    )
                    .matches(einRegex, t("legalBusinessEinValidation")),
        })
        .when([], {
            is: () => federalTaxType === FederalTaxType.Itin,
            then: (schema) =>
                schema
                    .min(
                        11,
                        `${t("einLabel")} ${t("einLabelValidationMessage")}`,
                    )
                    .matches(itinRegex, t("legalBusinessItinValidation")),
        })
        .when([], {
            is: () => federalTaxType === FederalTaxType.Ssn,
            then: (schema) =>
                schema
                    .min(
                        11,
                        `${t("einLabel")} ${t("einLabelValidationMessage")}`,
                    )
                    .matches(ssnRegex, t("legalBusinessSsnValidation")),
        });

    const businessNameValidation = Yup.string().trim();

    const commonAddressValidation = {
        address1: Yup.string().trim(),
        address2: Yup.string().nullable(),
        city: Yup.string().trim(),
        state: Yup.string(),
        zip: Yup.string(),
        country: Yup.string(),
    };

    return Yup.object().shape({
        //Business Information
        federalTaxId: federalTaxIdValidation,
        federalTaxType: Yup.string().required(t("legalChoiceLabel")),
        businessOpenDate: Yup.string()
            .nullable()
            .test("valid", t("invalidDateValidationMessage"), (val) =>
                moment(val, DateFormat.MM_DD_YYYY, true).isValid(),
            )
            .test("", t("invalidDateBeforeToday"), (val) =>
                moment(val, DateFormat.MM_DD_YYYY, true).isBefore(
                    moment(),
                    "day",
                ),
            )
            .test(
                "length",
                t("invalidDateValidationMessage"),
                (val) => val?.replace(/[-._/]/g, "").length === 8,
            )
            .required(t("requiredValidationMessage")),
        typeOfOwnership: Yup.mixed<OwnershipTypes>()
            .oneOf(Object.values(OwnershipTypes))
            .required(
                `${t("typeOfOwnershipLabel")} ${t(
                    "requiredValidationPrefixMessage",
                )}`,
            ),
        // business
        businessContact: Yup.object().shape({
            businessName: businessNameValidation.when([], {
                is: () => !isProprietor,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("businessName")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
            firstName: businessNameValidation.when([], {
                is: () => isProprietor,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("firstNameLabel")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
            lastName: businessNameValidation.when([], {
                is: () => isProprietor,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("lastNameLabel")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
        }),
        businessAddress: Yup.object().shape({
            address1: commonAddressValidation.address1.when([], {
                is: () => !isLegalAddressSame,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("address1Label")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
            address2: commonAddressValidation.address2.when([], {
                is: () => !isLegalAddressSame,
                then: (schema) => schema.concat(maxLengthValidation(100, t)),
            }),
            city: commonAddressValidation.city.when([], {
                is: () => !isLegalAddressSame,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("cityLabel")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
            state: commonAddressValidation.state.when([], {
                is: () => !isLegalAddressSame,
                then: (schema) =>
                    schema.required(
                        `${t("stateLabel")} ${t(
                            "requiredValidationPrefixMessage",
                        )}`,
                    ),
            }),
            zip: commonAddressValidation.zip.when([], {
                is: () => !isLegalAddressSame,
                then: (schema) =>
                    validateZip(
                        schema,
                        t("invalidZipValidationMessage"),
                        t("requiredValidationMessage"),
                    ),
            }),
            country: commonAddressValidation.country.when([], {
                is: () => !isLegalAddressSame,
                then: (schema) =>
                    schema.required(
                        `${t("countryLabel")} ${t(
                            "requiredValidationPrefixMessage",
                        )}`,
                    ),
            }),
        }),

        // IRS
        irsAddress: Yup.object().shape({
            address1: commonAddressValidation.address1.when([], {
                is: () => !isLegalIrsAddressSame,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("address1Label")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
            address2: commonAddressValidation.address2.when([], {
                is: () => !isLegalIrsAddressSame,
                then: (schema) => schema.concat(maxLengthValidation(100, t)),
            }),
            city: commonAddressValidation.city.when([], {
                is: () => !isLegalIrsAddressSame,
                then: (schema) =>
                    schema
                        .concat(maxLengthValidation(100, t))
                        .required(
                            `${t("cityLabel")} ${t(
                                "requiredValidationPrefixMessage",
                            )}`,
                        ),
            }),
            state: commonAddressValidation.state.when([], {
                is: () => !isLegalIrsAddressSame,
                then: (schema) =>
                    schema.required(
                        `${t("stateLabel")} ${t(
                            "requiredValidationPrefixMessage",
                        )}`,
                    ),
            }),
            zip: commonAddressValidation.zip.when([], {
                is: () => !isLegalIrsAddressSame,
                then: (schema) =>
                    validateZip(
                        schema,
                        t("invalidZipValidationMessage"),
                        t("requiredValidationMessage"),
                    ),
            }),
            country: commonAddressValidation.country.when([], {
                is: () => !isLegalIrsAddressSame,
                then: (schema) =>
                    schema.required(
                        `${t("countryLabel")} ${t(
                            "requiredValidationPrefixMessage",
                        )}`,
                    ),
            }),
        }),
    });
};
