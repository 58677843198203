import React from "react";
import { isMobile } from "react-device-detect";
import { BodyText, Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { StepPageLayout } from "app/components/StepPageLayout";
import { IPageComponentProps } from "app/types";
import { Breakpoint, useBreakpoint, useStepNavigate } from "features";
import { BusinessRebookAction, BusinessRebookActionType } from "features/store";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import photoRestaurant1 from "../../images/restaurant-back.webp";
import photoRestaurant2 from "../../images/restaurant-front.webp";
import photoRetail1 from "../../images/retail-back.webp";
import photoRetail2 from "../../images/retail-front.webp";

import styles from "./HomePage.module.scss";

export const HomePage = ({ appStore, formikStore }: IPageComponentProps) => {
    const { t } = useTranslation();
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    const getState = useDerivedState(
        appStore,
        ({
            data: {
                config: { businessName, businessRebookAction, isRestaurant },
                progressBlock: {
                    navigation: { availableStepsNames },
                },
            },
        }) => ({
            businessName,
            businessRebookAction,
            isApplicationInProgress: availableStepsNames.length !== 1,
            isRestaurant,
        }),
    )[0];
    const {
        businessName,
        businessRebookAction,
        isApplicationInProgress,
        isRestaurant,
    } = getState();
    const [photoFront, photoBack] = isRestaurant
        ? [photoRestaurant1, photoRestaurant2]
        : [photoRetail1, photoRetail2];

    const { goToTheActiveStep } = useStepNavigate({ appStore });

    const pointOfSaleDescription = (
        <>
            {t("pointOfSaleDescription1")}
            {<b>{t("pointOfSaleDescription2")}</b>}
        </>
    );

    const resolveTitle = (
        isApplicationInProgress: boolean,
        businessRebookAction: BusinessRebookActionType,
    ) => {
        switch (businessRebookAction) {
            case BusinessRebookAction.StructureChanged:
                return (
                    <>
                        <p className="m-0">{t("welcomeBusinessStructure")}</p>
                        <p className="m-0">{t("welcomeWeGotYou")}</p>
                    </>
                );
            case BusinessRebookAction.OwnershipChange:
                return (
                    <>
                        <p className="m-0">{t("welcomeOwnership")}</p>
                        <p className="m-0">{t("welcomeWeGotYou")}</p>
                    </>
                );
            default:
                return (
                    <>
                        {isApplicationInProgress
                            ? t("welcomeBack")
                            : t("welcomeToSpoton", {
                                  businessName,
                              })}
                    </>
                );
        }
    };

    const resolveDescription = (
        isApplicationInProgress: boolean,
        businessRebookAction: BusinessRebookActionType,
    ) => {
        if (businessRebookAction) {
            return <>{t("welcomeBusinessDescription")}</>;
        }
        return (
            <>
                {isApplicationInProgress
                    ? t("welcomeBackSubtitle")
                    : pointOfSaleDescription}
            </>
        );
    };

    const onClickContinue = () => {
        goToTheActiveStep();
    };

    return (
        <StepPageLayout
            appStore={appStore}
            id="almost-done-step"
            formikStore={formikStore}
            width="wide"
            isFooterHidden={!isLowerThanTablet}
            footerClassName="shadow-none"
            isGoBackHidden={true}
            customPrimaryFooterButton={{
                onClick: onClickContinue,
            }}
        >
            <div className={styles.Container}>
                <>
                    <div className={clsx(styles.Content)}>
                        <main className={clsx(styles.Main)}>
                            <div className={styles.Main___title}>
                                {isMobile ? (
                                    <h3 className="text-[30px] font-bold">
                                        {resolveTitle(
                                            isApplicationInProgress,
                                            businessRebookAction,
                                        )}
                                    </h3>
                                ) : (
                                    <h1 className="text-[48px] font-bold">
                                        {resolveTitle(
                                            isApplicationInProgress,
                                            businessRebookAction,
                                        )}
                                    </h1>
                                )}
                                <p className={styles.Main_subtitle}>
                                    {resolveDescription(
                                        isApplicationInProgress,
                                        businessRebookAction,
                                    )}
                                </p>
                            </div>
                            <div className={styles.Main___images}>
                                <div className={styles.Main_imageContainer}>
                                    <img
                                        className={clsx(
                                            styles.Main_backImage,
                                            styles.Main_image,
                                        )}
                                        src={photoFront}
                                        alt="homepageFrontImage"
                                    />
                                    <img
                                        className={clsx(
                                            styles.Main_frontImage,
                                            styles.Main_image,
                                        )}
                                        src={photoBack}
                                        alt="homepageBackImage"
                                    />
                                </div>
                            </div>
                            {!isLowerThanTablet && (
                                <div className={styles.Main___button}>
                                    <BodyText
                                        size="medium"
                                        className={styles.List_item}
                                    >
                                        <Button
                                            onClick={onClickContinue}
                                            className={styles.Link}
                                            id={`${name}-task`}
                                        >
                                            <span className={styles.Link_text}>
                                                {t("continueButton")}
                                            </span>
                                        </Button>
                                    </BodyText>
                                </div>
                            )}
                        </main>
                    </div>
                </>
            </div>
        </StepPageLayout>
    );
};
