import React, { useRef } from "react";
import InputMask from "react-input-mask";
import { TextInput } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField } from "formik";

import { DateFormat } from "features/common/types";

import { IDateInputFieldProps } from "./DateInputField.types";

export function DateInputField<T>(props: IDateInputFieldProps<T>) {
    const {
        name,
        label,
        className,
        value,
        onChange = () => ({}),
        onBlur: onBlurProp = () => ({}),
        onFocus = () => ({}),
        errorMessage,
        placeholder,
        id,
        inputMode,
        dataDdPrivacy,
        isClearable = true,
        isDisabled = false,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [field, meta] = useField(name);

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        onBlurProp(e);
        field.onBlur(e);
    };

    return (
        <InputMask
            {...field}
            mask="99/99/9999"
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlurField}
            onFocus={onFocus}
            id={id || `${name}-field`}
            inputRef={inputRef}
            disabled={isDisabled}
            placeholder={placeholder}
            maskPlaceholder={DateFormat.MM_DD_YYYY}
            inputMode={inputMode || "numeric"}
        >
            <TextInput
                disabled={isDisabled}
                className={clsx(className, "text-left")}
                label={label}
                errorMessage={meta.touched ? errorMessage ?? meta.error : ""}
                placeholder={placeholder}
                data-dd-privacy={dataDdPrivacy}
                clearable={isClearable}
            />
        </InputMask>
    );
}

export default DateInputField;
