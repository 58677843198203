import React from "react";
import InputMask from "react-input-mask";
import { parsePhoneNumber } from "react-phone-number-input";
import { TextInput } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField } from "formik";

import { IPhoneInputFieldProps } from "./PhoneInputField.types";

export function PhoneInputField<T>(props: IPhoneInputFieldProps<T>) {
    const {
        name,
        label,
        className,
        formik: { handleBlur, setFieldValue },
        onChange = () => ({}),
        onBlur = () => ({}),
        onFocus = () => ({}),
        id,
        isClearable = true,
        errorMessage,
    } = props;

    const [field, meta] = useField(name);
    const { value } = field;

    const phoneFieldData = value
        ? {
              ...parsePhoneNumber(value),
              isComplete: true,
          }
        : {
              nationalNumber: value,
              isComplete: false,
          };
    let { nationalNumber } = phoneFieldData;

    if (!nationalNumber) {
        const wrongFormatRegex = /[()\s-]/;
        if (wrongFormatRegex.test(value)) {
            nationalNumber = value.replace(/[()\s-]/g, "");
        } else {
            nationalNumber = value;
        }
    }

    const onFocusField = (e: React.SyntheticEvent<Element, Event>) => {
        onFocus(e);
    };

    const onBlurField = (e: React.SyntheticEvent<Element, Event>) => {
        handleBlur(e);
        onBlur(e);
    };

    const onChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = e.target.value.replace(/["'()_-\s]/g, "");
        setFieldValue(name, phoneNumber);
        onChange(e);
    };

    return (
        <InputMask
            {...field}
            type="tel"
            mask={"(999) 999-9999"}
            value={nationalNumber ?? ""}
            onChange={onChangeField}
            onBlur={onBlurField}
            onFocus={onFocusField}
            id={id || `${name}-field`}
            disabled={false}
            inputMode="tel"
        >
            <TextInput
                disabled={false}
                className={clsx(className, "text-left")}
                label={label}
                errorMessage={meta.touched ? errorMessage ?? meta.error : ""}
                clearable={isClearable}
            />
        </InputMask>
    );
}

export default PhoneInputField;
