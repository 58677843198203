import React from "react";

import { useTranslation } from "i18nConfig";

import { ILineProduct } from "../PlatformView.types";
import { FormatFunction, TypedColumns } from "../types";

type ProductRowProps = {
    item: ILineProduct;
    columns: TypedColumns;
};
export const ProductRow = ({ item, columns }: ProductRowProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-2 pt-3 w-full">
            {columns
                .filter((col) => !col.ignoreOnMobile)
                .map(({ key, label, format }) => (
                    <div key={key} className="flex items-center">
                        <div className="flex w-full justify-between items-center text-sm">
                            <div className="text-neutral-60">{t(label)}</div>
                            <div className="text-neutral-90">
                                {(format as FormatFunction<typeof key>)?.(
                                    item[key],
                                ) ?? item[key]}
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
