import { moneyFormat, percentFormat } from "features/utils";

import { Column, Keys, TypedColumns } from "./types";

const createColumn = <T extends Keys>(column: Column<T>): Column<T> => column;

export const hardwareAndImplementationColumns = [
    createColumn({
        key: "name",
        label: "name",
        ignoreOnMobile: true,
    }),
    createColumn({
        key: "price",
        label: "price",
        format: moneyFormat,
    }),
    createColumn({
        key: "quantity",
        label: "quantity",
    }),
    createColumn({
        key: "subTotalPrice",
        label: "subTotalPrice",
        format: moneyFormat,
        doCalculateSum: true,
    }),
    createColumn({
        key: "totalPrice",
        label: "totalPrice",
        format: moneyFormat,
        doCalculateSum: true,
    }),
    createColumn({
        key: "monthlyInstallment",
        label: "monthlyInstallment",
        format: moneyFormat,
        doCalculateSum: true,
    }),
] satisfies TypedColumns;

export const softwareColumns = [
    createColumn({
        key: "name",
        label: "name",
        ignoreOnMobile: true,
    }),
    createColumn({
        key: "price",
        label: "price",
        format: moneyFormat,
    }),
    createColumn({
        key: "quantity",
        label: "quantity",
    }),
    createColumn({
        key: "subTotalPrice",
        label: "subTotalPrice",
        format: moneyFormat,
        doCalculateSum: true,
    }),
    createColumn({
        key: "totalPrice",
        label: "totalPrice",
        format: moneyFormat,
        doCalculateSum: true,
    }),
    createColumn({
        key: "qliTemporaryDiscountMonths",
        label: "temporaryDiscountMonths",
    }),
    createColumn({
        key: "qliTemporaryDiscount",
        label: "temporaryDiscountPercentage",
        format: (value) => percentFormat(value, "", 0),
    }),
] satisfies TypedColumns;
