import React from "react";
import { SVGProps } from "react";

export const ImageElement = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_18040_130570)">
                <rect
                    x="8.06006"
                    y="10.6045"
                    width="63.143"
                    height="88.6298"
                    rx="8"
                    transform="rotate(2.02752 8.06006 10.6045)"
                    fill="#D3E5FF"
                />
                <rect
                    x="4.06079"
                    y="6.60474"
                    width="63.143"
                    height="88.6298"
                    rx="8"
                    transform="rotate(2.02752 4.06079 6.60474)"
                    fill="#99DDB9"
                />
                <path
                    d="M13.7041 56.8478L55.8465 58.1916M12.9419 66.1412L55.0842 67.485"
                    stroke="#1F9353"
                    strokeWidth="3.37694"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    opacity="0.1"
                    d="M0.925185 101.181L64.0287 103.415L65.8049 53.2415L35.1543 59.5L2.18736 65.5283L0.925185 101.181Z"
                    fill="#08031D"
                />
                <ellipse
                    cx="35.4929"
                    cy="31.5682"
                    rx="14.8691"
                    ry="14.8691"
                    transform="rotate(2.02752 35.4929 31.5682)"
                    fill="white"
                />
                <path
                    d="M26.6462 34.329L31.5796 38.2347L43.2545 25.9554"
                    stroke="#1F9353"
                    strokeWidth="4.05233"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_18040_130570">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
