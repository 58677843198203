import React, { useEffect, useRef } from "react";
import { flip, offset, size } from "@floating-ui/dom";
import { SelectMenu, useNexus } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField, useFormikContext } from "formik";

import { ISelectFieldProps } from "./SelectField.types";
import { getCSSVariable, useId } from "./SelectField.utils";

import styles from "./SelectField.module.scss";

export function SelectField<T>(props: ISelectFieldProps<T>) {
    const {
        id: idProp,
        name,
        label,
        options,
        className,
        onChange,
        errorMessage,
        placeholder,
        isDisabled,
    } = props;
    const [field, meta, helpers] = useField(name);

    const { isSubmitting, isValidating } = useFormikContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const nameId = useId();
    const id = idProp || `${name}-${nameId}-field`;
    const { theme } = useNexus();

    useEffect(() => {
        if (isValidating && isSubmitting) {
            helpers.setTouched(true);
            helpers.setError(meta.error);
        }
    }, [isValidating, isSubmitting]);

    const optionsMap: { [key in string]: string } = {};
    for (const option of options) {
        optionsMap[option.value] = option.label;
    }

    const offsetValue = 8;
    const footerHeight = parseInt(
        getCSSVariable("--footer", containerRef.current),
    );
    const headerHeight = parseInt(
        getCSSVariable("--header", containerRef.current),
    );

    return (
        <div
            ref={containerRef}
            className={clsx(className, styles.SelectField_container)}
        >
            <SelectMenu
                {...field}
                disabled={isDisabled}
                id={id}
                name={name}
                className={clsx(className, styles.SelectField, "text-left")}
                label={label}
                onChange={async (e, newValue: string) => {
                    field.onChange(e);
                    await helpers.setValue(newValue);
                    helpers.setError(undefined);
                    // eslint-disable-next-line prettier/prettier
                    if(newValue) {
                        onChange?.(newValue);
                    }
                }}
                errorMessage={meta.touched ? errorMessage ?? meta.error : ""}
                options={options.map((option) => ({
                    value: option.value,
                    label: option.label,
                }))}
                placeholder={placeholder || ""}
                // @todo Temporary hack until the fix in the aero library gets released
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                slotProps={{
                    popup: {
                        className: clsx(styles.Popup, theme.className),
                        disablePortal: true,
                        placement: "bottom-start",
                        middleware: [
                            flip({
                                padding: footerHeight + offsetValue,
                            }),
                            size({
                                padding: {
                                    top: headerHeight + offsetValue * 2,
                                    bottom: footerHeight + offsetValue * 2,
                                },
                                apply({
                                    availableWidth,
                                    availableHeight,
                                    elements,
                                }) {
                                    Object.assign(elements.floating.style, {
                                        maxWidth: `${Math.max(
                                            0,
                                            availableWidth,
                                        )}px`,
                                        maxHeight: `${Math.max(
                                            0,
                                            Math.min(availableHeight, 288),
                                        )}px`,
                                    });
                                },
                            }),
                            offset(offsetValue),
                        ],
                    },
                    listbox: { className: styles.List },
                }}
            />
        </div>
    );
}

export default SelectField;
