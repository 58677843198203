import React from "react";
import { Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useStepNavigate } from "features/utils";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { IFooterProps, IStatelessFooterProps } from "./Footer.types";

import styles from "./Footer.module.scss";

export const Footer = ({
    formikStore,
    appStore,
    customPrimaryButton,
    customSecondaryButton,
    customBottomCaption,
}: IFooterProps) => {
    const { t } = useTranslation();

    const getState = useDerivedState(
        appStore,
        ({
            data: { progressBlock },
            loading: {
                isCompleteActiveStepLoading,
                isSetConfigLoading,
                isSubmitAndGoForwardLoading,
            },
        }) => ({
            ...progressBlock,
            isLoading: isCompleteActiveStepLoading || isSetConfigLoading,
            isLoadingContinue: isSubmitAndGoForwardLoading,
        }),
    )[0];

    const { isLoading, isLoadingContinue } = getState();

    const { goForward, goBack, activeStepName } = useStepNavigate({ appStore });

    const onContinue = async () => {
        const defaultAction = async () => {
            await goForward({ formikStore });
        };
        if (customPrimaryButton?.onClick) {
            customPrimaryButton.onClick(defaultAction);
        } else {
            await defaultAction();
        }
    };

    const onSecondaryButtonClick = () => {
        if (customSecondaryButton?.onClick) {
            customSecondaryButton.onClick(goBack);
        }
    };

    return (
        <div className={styles.Wrapper} id="footer">
            <div className={styles.Footer}>
                <div className={styles.Footer_buttons}>
                    {!customPrimaryButton?.hidden && (
                        <Button
                            onClick={onContinue}
                            className={clsx(
                                styles.Footer_continueBtn,
                                customPrimaryButton?.className,
                            )}
                            variant="primary"
                            disabled={isLoading}
                            isLoading={
                                customPrimaryButton?.isLoading ||
                                isLoadingContinue
                            }
                            data-testid="continue-btn"
                            {...getAnalyticProps(
                                AnalyticElements.ContinueButton,
                                {
                                    context: "footer",
                                    step: activeStepName,
                                },
                            )}
                        >
                            {customPrimaryButton?.label || t("continueButton")}
                        </Button>
                    )}
                    {customSecondaryButton && !customSecondaryButton.hidden && (
                        <Button
                            onClick={onSecondaryButtonClick}
                            className={clsx(
                                styles.BackBtn,
                                customSecondaryButton?.className,
                            )}
                            variant="secondary"
                            disabled={isLoading}
                            isLoading={
                                isLoadingContinue ||
                                customSecondaryButton.isLoading
                            }
                            data-testid="secondary-btn"
                            {...getAnalyticProps(
                                AnalyticElements.SecondaryButton,
                                {
                                    context: "footer",
                                    step: activeStepName,
                                },
                            )}
                        >
                            {customSecondaryButton.label ||
                                t("cancelButtonLabel")}
                        </Button>
                    )}
                </div>
                {customBottomCaption}
            </div>
        </div>
    );
};

export const StatelessFooter = ({
    customPrimaryButton,
    customSecondaryButton,
}: IStatelessFooterProps) => {
    const { t } = useTranslation();

    const onContinue = async () => {
        if (customPrimaryButton?.onClick) {
            customPrimaryButton.onClick();
        }
    };

    const onSecondaryButtonClick = async () => {
        if (customSecondaryButton?.onClick) {
            customSecondaryButton.onClick();
        }
    };

    return (
        <div className={styles.Wrapper} id="footer">
            <div className={styles.Footer}>
                <div className={styles.Footer_buttons}>
                    {!customPrimaryButton?.hidden && (
                        <Button
                            onClick={onContinue}
                            className={clsx(
                                styles.Footer_continueBtn,
                                customPrimaryButton?.className,
                            )}
                            variant="primary"
                            disabled={customPrimaryButton?.isLoading}
                            isLoading={customPrimaryButton?.isLoading}
                            data-testid="continue-btn"
                        >
                            {customPrimaryButton?.label || t("continueButton")}
                        </Button>
                    )}
                    {customSecondaryButton && !customSecondaryButton.hidden && (
                        <Button
                            onClick={onSecondaryButtonClick}
                            className={clsx(
                                styles.BackBtn,
                                customSecondaryButton?.className,
                            )}
                            variant="secondary"
                            disabled={customSecondaryButton.isLoading}
                            isLoading={customSecondaryButton.isLoading}
                            data-testid="secondary-btn"
                        >
                            {customSecondaryButton.label ||
                                t("cancelButtonLabel")}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
