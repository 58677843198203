import React from "react";
import clsx from "clsx";

import { TranslationKeys, useTranslation } from "i18nConfig";

interface IProps {
    className?: string;
    message: string & TranslationKeys;
}

// We need to add this to separate file so we can use the i18n hooks.
// We can't have useTranslation in a class component, and right now there
// is no other way to implement ErrorMessage.component.tsx than
// with a class component
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
export const ErrorMessage = ({ className, message }: IProps) => {
    const { t } = useTranslation();

    return (
        <h2 className={clsx(className, "text-2xl font-bold")}>{t(message)}</h2>
    );
};
