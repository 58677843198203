import React, { useEffect, useRef } from "react";

import { Breakpoint, useBreakpoint } from "features/index";

import { DesktopDetailsView } from "./DesktopDetailsView";
import { AccordionMobile } from "./MobileDetailsView";
import { IPlatformStep } from "./PlatformView.types";
import { QuoteSections } from "./types";

type DetailsViewProps = {
    data: IPlatformStep;
    scrollToSection?: QuoteSections;
};

export const DetailsView = (props: DetailsViewProps) => {
    const { data, scrollToSection } = props;
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollToSection) {
            const element = document.getElementById(scrollToSection);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [scrollToSection]);

    return (
        <div className="h-full pt-6" ref={ref}>
            {isLowerThanTablet ? (
                <AccordionMobile data={data} />
            ) : (
                <DesktopDetailsView data={data} />
            )}
        </div>
    );
};
