import { defer, LoaderFunctionArgs, redirect } from "react-router-dom";

import { AppRoutes } from "app/routes";
import { routeToStep, stepToRoute } from "features/common";
import { getSiteSurveyPromise } from "features/site-survey/components/SiteSurvey";
import { AppStore } from "features/store";

export const getConfigOrRedirect = async (
    appStore: AppStore,
    route: AppRoutes,
    token?: string | null,
) => {
    try {
        const requestedStepName = routeToStep(route);
        const appStoreData = appStore.getState().data;
        const isConfigLoaded = appStoreData.config.isConfigLoaded;
        let store = appStoreData;

        if (!isConfigLoaded) {
            store = await appStore.asyncDispatch.setConfig({ token });
        }

        const {
            config: { isSubmitted, opportunityId, opportunityType },
            profile: { email },
            progressBlock: {
                navigation: {
                    availableStepsNames,
                    latestStepName,
                    activeStepName,
                },
            },
        } = store;

        /** Add user properties to heap */
        if (window?.heap?.loaded) {
            window.heap.addUserProperties({
                opportunityId,
                opportunityType,
                email,
            });
        }

        /**
         * If the application is already submitted we redirect user to success page
         */
        if (isSubmitted) {
            if (route !== AppRoutes.Success) {
                return redirect(AppRoutes.Success);
            }
            return true;
        }

        if (
            !isSubmitted &&
            !requestedStepName &&
            !!route &&
            route !== AppRoutes.Home
        ) {
            const activeStepRoute = stepToRoute(activeStepName);
            return redirect(activeStepRoute);
        }

        /**
         * When user opens app by typing URL, we need to inform BE about that
         */
        const latestStepRoute = stepToRoute(latestStepName);

        if (
            requestedStepName &&
            availableStepsNames.includes(requestedStepName) &&
            !isConfigLoaded &&
            (!appStore.state.data.progressBlock.view.activeStep?.name ||
                appStore.state.data.progressBlock.view.activeStep.name !==
                    requestedStepName)
        ) {
            store = await appStore.asyncDispatch.setStep(requestedStepName);
        }

        /**
         * If user tries to access a step that is not available for him
         * or if user tries to access success page without submitting the form
         * we redirect him to the latest available step
         */
        if (
            ((requestedStepName &&
                !availableStepsNames.includes(requestedStepName)) ||
                (!isSubmitted && route === AppRoutes.Success)) &&
            latestStepRoute
        ) {
            store = await appStore.asyncDispatch.setStep(requestedStepName);
            const availableStep = store.progressBlock.navigation.activeStepName;
            if (requestedStepName !== availableStep) {
                return redirect(stepToRoute(availableStep));
            }
        } else if (!latestStepRoute) {
            throw new Error("No latest step route found");
        }

        // /** At this point the requestedStepName should be considered as a valid one
        //  * But it may happen that the state store is not up to date (e.g. when going back)
        //  */
        if (
            requestedStepName &&
            store.progressBlock.navigation.activeStepName !== requestedStepName
        ) {
            store = await appStore.asyncDispatch.setStep(requestedStepName);
        }

        /**
         * Otherwise mark store as loaded
         */

        return !!store;
    } catch (err: any) {
        console.error(err);
        throw err;
    }
};

// TODO: add token validation with BE
export const loadConfig =
    (appStore: AppStore) => async (args: LoaderFunctionArgs) => {
        const {
            request: { url },
        } = args;

        const { searchParams, pathname: route } = new URL(url);
        const token = searchParams.get("token");
        return getConfigOrRedirect(appStore, route as AppRoutes, token);
    };

type VoidLoaderFunction = (args: LoaderFunctionArgs) => Promise<unknown>;
export const loadLoaders =
    (loaders: VoidLoaderFunction[]) => async (args: LoaderFunctionArgs) => {
        for (const loader of loaders) {
            const value = await loader(args);
            /** Check if redirect */
            if (value instanceof Response) {
                return value;
            }
        }
        return defer({
            isLoaded: true,
        });
    };

export const loadManualAccountData = (appStore: AppStore) => async () => {
    await appStore.asyncDispatch.getManualAccountData(false);
};

export const loadBillingSelector = (appStore: AppStore) => async () => {
    await appStore.asyncDispatch.getManualAccountData(true);
};

export const loadLinkedAccount = (appStore: AppStore) => async () => {
    await appStore.asyncDispatch.getLinkedAccount();
};

export const loadSiteSurvey = async ({ request }: { request: Request }) => {
    const url = new URL(request.url);
    const siteSurveyId = url.searchParams.get("id");

    if (!siteSurveyId) {
        throw new Error("Site survey id is missing");
    }

    const siteSurveyResponsePromise = getSiteSurveyPromise(siteSurveyId);

    return defer({ siteSurveyResponsePromise });
};
