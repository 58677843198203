import {
    applicantStepAsyncReducers,
    applicantStepReducers,
} from "./applicantStep";
import { billingStepAsyncReducers, billingStepReducers } from "./billingStep";
import {
    businessStepAsyncReducers,
    businessStepReducers,
} from "./businessStep";
import { commonAsyncReducers, commonReducers } from "./common";
import { taxesAsyncReducers } from "./confirmTax";
import {
    fasterFundingStepAsyncReducers,
    fasterFundingStepReducers,
} from "./fasterFundingStep";
import { ownersStepAsyncReducers, ownersStepReducers } from "./ownersStep";
import {
    platformStepAsyncReducers,
    platformStepReducers,
} from "./platformStep";
import {
    shippingStepAsyncReducers,
    shippingStepReducers,
} from "./shippingStep";
import {
    signatureStepAsyncReducers,
    signatureStepReducers,
} from "./signatureStep";
import {
    termsAndConditionsAsyncReducers,
    termsAndConditionsReducers,
} from "./termsAndConditionsStep";

export const asyncReducers = {
    ...applicantStepAsyncReducers,
    ...billingStepAsyncReducers,
    ...businessStepAsyncReducers,
    ...commonAsyncReducers,
    ...ownersStepAsyncReducers,
    ...platformStepAsyncReducers,
    ...shippingStepAsyncReducers,
    ...signatureStepAsyncReducers,
    ...fasterFundingStepAsyncReducers,
    ...termsAndConditionsAsyncReducers,
    ...taxesAsyncReducers,
};

export const reducers = {
    ...applicantStepReducers,
    ...billingStepReducers,
    ...businessStepReducers,
    ...commonReducers,
    ...ownersStepReducers,
    ...platformStepReducers,
    ...shippingStepReducers,
    ...signatureStepReducers,
    ...fasterFundingStepReducers,
    ...termsAndConditionsReducers,
};
