import { NavigateFunction } from "react-router-dom";

import {
    InactiveStepName,
    StepName,
    StepView,
    TermsAndConditionsSubmition,
} from "features";
import { stepToRoute } from "features/common";
import { AppStore, FormikStore } from "features/store";

import { ICreateDefaultStepsProps } from "./ProgressBlock.types";

export const onClickIndex =
    (
        appStore: AppStore,
        formikStore: FormikStore,
        navigate: NavigateFunction,
        stepName: StepName,
    ) =>
    () => {
        appStore.asyncDispatch
            .completeActiveStep(appStore, formikStore)
            .then((value) => {
                const {
                    progressBlock: {
                        error,
                        view: { activeStep },
                    },
                } = value;
                if (!error && activeStep) {
                    navigate(stepToRoute(stepName), { replace: true });
                }
            });
    };

export const prepareStepsViews = ({
    steps,
    activeStepName,
    onClickIndex,
    t,
}: ICreateDefaultStepsProps): {
    activeStep: StepView | undefined;
    steps: StepView[];
} => {
    let activeStep: StepView | undefined;
    const extendedSteps = steps.map((step) => {
        const stepView: StepView = {
            ...step,
            title: t(step.titleTranslationKey),
            onClick: onClickIndex,
        };
        if (step.name === activeStepName) {
            activeStep = stepView;
        }
        return stepView;
    });

    return {
        steps: extendedSteps,
        activeStep,
    };
};

export const stepsToStepViewMap = (): Record<
    StepName | InactiveStepName,
    Pick<StepView, "titleTranslationKey"> & Partial<StepView>
> => ({
    [StepName.Quote]: {
        titleTranslationKey: "quoteStepTitle",
        onSubmit: async ({ appStore }) => {
            await appStore.asyncDispatch.setPlatformStepComplete();
        },
    },
    [StepName.Business]: {
        titleTranslationKey: "businessStepTitle",
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.Business);
        },
    },
    [StepName.BusinessLegal]: {
        titleTranslationKey: "businessStepTitle",
        subStep: {
            beforeParentStep: false,
        },
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.BusinessLegal);
        },
    },
    [StepName.Applicant]: {
        titleTranslationKey: "ownersStepTitle",
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.Applicant);
        },
    },
    [StepName.Owners]: {
        titleTranslationKey: "ownersStepTitle",
        subStep: {
            beforeParentStep: false,
        },
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.Owners);
        },
    },
    [StepName.Shipping]: {
        titleTranslationKey: "shippingTableHeader",
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.Shipping);
        },
    },
    [StepName.BillingSelector]: {
        titleTranslationKey: "billingPaymentsStepTitle",
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.BillingSelector);
        },
        subStep: {
            beforeParentStep: true,
        },
    },
    [StepName.BillingForm]: {
        titleTranslationKey: "billingPaymentsStepTitle",
        subStep: {
            beforeParentStep: true,
        },
        onSubmit: async ({ formikStore }) => {
            await formikStore.submitFormRef(StepName.BillingForm);
        },
    },
    [StepName.BillingSuccess]: {
        titleTranslationKey: "billingPaymentsStepTitle",
        onSubmit: async ({ appStore }) => {
            await appStore.asyncDispatch.setBillingStepAsCompleted();
        },
    },
    [StepName.FasterFunding]: {
        titleTranslationKey: "fasterFundingStepHeader",
        onSubmit: async ({ appStore }) => {
            await appStore.asyncDispatch.completeFasterFundingStep();
        },
        name: StepName.FasterFunding,
        subStep: {
            beforeParentStep: false,
        },
    },
    [StepName.Signature]: {
        titleTranslationKey: "signatureStepTitle",
        onSubmit: async ({ appStore, formikStore }) => {
            if (appStore.state.data.config.clickToSign) {
                await formikStore.submitFormRef(
                    TermsAndConditionsSubmition.SubmitTerms,
                );
            } else {
                await appStore.asyncDispatch.completeSignatureStep();
                console.log("SIGNED");
            }
        },
    },
});
