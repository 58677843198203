import React, { useEffect, useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { BodyText } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";

import {
    getTermsAndConditionsValidation,
    IFormProps,
    ITermsAndConditionsStep,
} from "features/application-wizard";
// types
import {
    CheckboxField,
    DateFormat,
    DateInputField,
    ScrollToFieldError,
    TermsAndConditionsSubmition,
    TextField,
} from "features/common";
import { Breakpoint, useBreakpoint } from "features/hooks";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import {
    TermsContent,
    TermsMobileSign,
    TermsSignatureCanvas,
} from "./components";
import { generateDateTimestamp } from "./utils";
// Component
export const TermsAndConditions = ({ appStore, formikStore }: IFormProps) => {
    // translations
    const { t } = useTranslation();

    const [isTermsScrolled, setIsTermsScrolled] = useState(false);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [isSigning, setIsSigning] = useState(false);

    const [trimmedDataURL, setTrimmedDataURL] = useState("");

    const signatureRef = useRef<ReactSignatureCanvas>(null);
    const mobileSignatureRef = useRef<ReactSignatureCanvas>(null);
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    // create formik ref and add to store
    const formRef = useRef<FormikProps<ITermsAndConditionsStep> | null>(null);
    useEffect(() => {
        // create formik ref and add to formikStore
        formikStore.dispatch.addRef(
            TermsAndConditionsSubmition.SubmitTerms,
            formRef,
        );
        return () => {
            formikStore.dispatch.removeRef(
                TermsAndConditionsSubmition.SubmitTerms,
            );
        };
    }, []);

    const [getState] = useDerivedState(
        appStore,
        ({
            data: { termsAndConditionsStep, businessStep, applicantStep },
            loading: { isCompleteActiveStepLoading },
        }) => ({
            termsAndConditionsStep,
            businessStep,
            applicantStep,
            isLoading: isCompleteActiveStepLoading,
        }),
    );
    const { isLoading, termsAndConditionsStep, businessStep, applicantStep } =
        getState();

    const {
        businessContact: { businessName },
    } = businessStep;

    const {
        applicant: {
            title,
            contact: { firstName, lastName },
        },
    } = applicantStep;

    // whenever termsAndConditionsStep is updated,
    // we should make sure the state options and synced fields are updated
    useEffect(() => {
        if (!isLoading) {
            const { mm, dd, yyyy } = generateDateTimestamp();

            formRef.current?.setValues({
                ...termsAndConditionsStep,
                signerName: `${firstName} ${lastName}`,
                signLegalName: businessName,
                signDate: `${mm}/${dd}/${yyyy}`,
                signTitle: title,
            });
        }
    }, [termsAndConditionsStep]);

    const onSubmit = async () => {
        if (isTermsScrolled && shouldValidate) {
            await appStore.asyncDispatch.updateTermsAndConditionsStep(
                trimmedDataURL,
            );
        }
    };

    useEffect(() => {
        setTrimmedDataURL("");
        setIsSigning(false);
    }, [isLowerThanTablet]);

    const onStartDrawing = () => {
        formRef.current?.setFieldValue("trimmedDataURL", true);
    };

    const onClearDrawing = () => {
        formRef.current?.setFieldValue("trimmedDataURL", false);
    };

    useEffect(() => {
        formRef.current?.validateForm();
    }, [isTermsScrolled]);

    return (
        <div className="pt-6 tablet:pt-7 text-left">
            <TermsContent
                appStore={appStore}
                isTermsScrolled={isTermsScrolled}
                setIsTermsScrolled={setIsTermsScrolled}
            />
            <Formik
                innerRef={formRef}
                initialValues={termsAndConditionsStep}
                validationSchema={() =>
                    getTermsAndConditionsValidation(
                        t,
                        shouldValidate,
                        isTermsScrolled,
                    )
                }
                onSubmit={async () => {
                    await onSubmit();
                }}
                enableReinitialize={false}
            >
                {(formik: FormikProps<ITermsAndConditionsStep>) => {
                    return (
                        <Form>
                            <ScrollToFieldError formik={formik} />
                            <div className="pt-5 pb-11">
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,jsx-a11y/no-static-element-interactions */}
                                <CheckboxField
                                    formik={formik}
                                    className={clsx(
                                        "mr-[-0.25rem] text-base text-black text-left",
                                        !isTermsScrolled && "opacity-30",
                                    )}
                                    name="agreeToTermsAndConditions"
                                    label={t("termsAndConditionsTerms")}
                                    checked={
                                        formik.values.agreeToTermsAndConditions
                                    }
                                    onChange={() => {
                                        if (!isTermsScrolled) {
                                            if (
                                                formik.values
                                                    .agreeToTermsAndConditions
                                            ) {
                                                setShouldValidate(true);
                                            }
                                            formRef.current?.setFieldValue(
                                                "agreeToTermsAndConditions",
                                                false,
                                            );
                                        } else {
                                            setShouldValidate(!shouldValidate);
                                            formRef.current?.setFieldValue(
                                                "agreeToTermsAndConditions",
                                                !formik.values
                                                    .agreeToTermsAndConditions,
                                            );
                                        }
                                    }}
                                    customContainerClassName="flex-col"
                                />
                            </div>
                            <div className="pb-11">
                                <h5 className="pb-6 text-xl font-bold">
                                    {t("signLabel")}
                                </h5>
                                <div className="flex flex-col tablet:flex-row gap-0 tablet:gap-4 pb-4">
                                    <TextField
                                        className="flex-1 text-sm"
                                        formik={formik}
                                        isClearable={false}
                                        name="signerName"
                                        isDisabled
                                        label={t("signerNameLabel")}
                                        value={formik.values?.signerName}
                                    />
                                    <TextField
                                        className="flex-1 text-sm"
                                        formik={formik}
                                        isClearable={false}
                                        name="signTitle"
                                        isDisabled
                                        label={t("titleLabel")}
                                        value={formik.values?.signTitle}
                                    />
                                </div>
                                <div className="flex flex-col tablet:flex-row gap-0 tablet:gap-4 pb-6">
                                    <TextField
                                        className="flex-1 text-sm"
                                        formik={formik}
                                        isClearable={false}
                                        name="signLegalName"
                                        isDisabled
                                        label={t("legalBusinessLabelName")}
                                        value={formik.values?.signLegalName}
                                    />
                                    <DateInputField
                                        className="flex-1 text-sm"
                                        formik={formik}
                                        isClearable={false}
                                        name="signDate"
                                        isDisabled
                                        label={t("dateLabel")}
                                        value={formik.values.signDate}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "signDate",
                                                e.target.value,
                                            );
                                        }}
                                        placeholder={DateFormat.MM_DD_YYYY}
                                    />
                                </div>
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                                <div
                                    role="button"
                                    onClick={() => setIsSigning(true)}
                                    className={clsx(
                                        "w-full h-[240px] flex items-center justify-center cursor-pointer",
                                        isSigning
                                            ? "bg-whit rounded-lg border border-sign-border"
                                            : "bg-icon-background",
                                    )}
                                >
                                    {trimmedDataURL && isLowerThanTablet ? (
                                        <div className="w-full h-full flex items-center justify-center relative">
                                            <BodyText
                                                size="medium"
                                                className="absolute top-4 right-4 text-base text-primary-primary-60"
                                                onClick={() =>
                                                    setIsSigning(true)
                                                }
                                            >
                                                {t("edit")}
                                            </BodyText>
                                            <img
                                                src={trimmedDataURL}
                                                alt="base"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {isSigning ? (
                                                <TermsSignatureCanvas
                                                    signatureRef={signatureRef}
                                                    trimmedDataURL={
                                                        trimmedDataURL
                                                    }
                                                    setTrimmedDataURL={
                                                        setTrimmedDataURL
                                                    }
                                                    onStartDrawing={
                                                        onStartDrawing
                                                    }
                                                    onClearDrawing={
                                                        onClearDrawing
                                                    }
                                                />
                                            ) : (
                                                <BodyText className="text-primary-primary-60 text-base">
                                                    {t(
                                                        "termsAndConditionsSignature",
                                                    )}
                                                </BodyText>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="h-6">
                                    <CheckboxField
                                        formik={formik}
                                        className="hidden invisible ml-[-20px]"
                                        label=""
                                        name="trimmedDataURL"
                                        checked={formik.values.trimmedDataURL}
                                    />
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            {isLowerThanTablet && isSigning && (
                <TermsMobileSign
                    mobileSignatureRef={mobileSignatureRef}
                    setTrimmedDataURL={setTrimmedDataURL}
                    setIsSigning={setIsSigning}
                    onStartDrawing={onStartDrawing}
                    onClearDrawing={onClearDrawing}
                />
            )}
        </div>
    );
};
