import React from "react";
import * as Accordion from "@radix-ui/react-accordion";

import { useTranslation } from "i18nConfig";

import { calculateSum } from "../helpers";
import { ILineProduct } from "../PlatformView.types";
import { FormatFunction, QuoteSections, TypedColumns } from "../types";
import { AccordionHeader } from "./AccordionHeader.component";
import { ProductRow } from "./ProductRow.component";
import { SectionTitle } from "./SectionTitle.component";
const { Content, Item } = Accordion;

type AccordionSectionProps = {
    data: ILineProduct[];
    columns: TypedColumns;
    title: string;
    allKeysRef?: React.MutableRefObject<Record<string, unknown>>;
    id: QuoteSections;
};
export const AccordionSection = ({
    data,
    columns,
    title,
    allKeysRef,
    id,
}: AccordionSectionProps) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col pt-4 relative" id={id}>
            <SectionTitle title={title} />
            {data.map((item, i) => {
                const newKey = `${item.name}-${i}`;

                // Update allKeysRef if provided
                if (allKeysRef) {
                    allKeysRef.current = {
                        ...(allKeysRef?.current ?? {}),
                        [newKey]: {},
                    };
                }

                return (
                    <Item key={newKey} value={newKey} className="pt-4">
                        <AccordionHeader
                            total={item.total}
                            label={
                                <span>
                                    {item.name}{" "}
                                    <span className="font-semibold text-neutral-60">
                                        x{item.quantity}
                                    </span>
                                </span>
                            }
                        />
                        <Content className="flex pb-4">
                            <ProductRow item={item} columns={columns} />
                        </Content>
                        <hr />
                    </Item>
                );
            })}

            {/* Total Section */}
            <Item value="section-total" className="pt-3">
                <AccordionHeader
                    className="font-semibold"
                    total={calculateSum(data, "totalPrice")}
                    label={t("totalPrice")}
                />
                <Content className="flex">
                    <div className="flex flex-col gap-2 pt-3 w-full">
                        {columns
                            .filter(
                                (item) =>
                                    !item.ignoreOnMobile && item.doCalculateSum,
                            )
                            .map(({ key, label, format }) => (
                                <div key={key} className="flex items-center">
                                    <div className="flex w-full justify-between items-center text-sm">
                                        <div className="text-neutral-60">
                                            {t(label)}
                                        </div>
                                        <div className="text-neutral-90">
                                            {(
                                                format as FormatFunction<
                                                    typeof key
                                                >
                                            )?.(calculateSum(data, key)) ??
                                                calculateSum(data, key)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </Content>
            </Item>
        </div>
    );
};
