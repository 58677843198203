import React, { useState } from "react";
import { Button } from "@spotoninc/nexus-react";

import { API_ENDPOINTS, httpFileCore } from "features";
import { useTranslation } from "i18nConfig";

export const DownloadQuotePDFButton = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            const response = await httpFileCore.get(
                `${API_ENDPOINTS.signup.PlatformStepDownloadPDF}`,
                { responseType: "blob" },
            );

            const blob = await response.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "SpotOn_Quote.pdf");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Download error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            variant="tonal"
            onClick={handleDownload}
            className="w-[164px] justify-center"
            isLoading={isLoading}
        >
            {t("downloadQuotePdf")}
        </Button>
    );
};
