import React from "react";
import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom";
import clsx from "clsx";

import { OwnersPage } from "app/components/OwnersPage";
import { PageLayout } from "app/components/PageLayout";
import { SignatureResolverPage } from "app/components/SignatureResolverPage";
import {
    loadBillingSelector,
    loadConfig,
    loadLinkedAccount,
    loadLoaders,
    loadManualAccountData,
    loadSiteSurvey,
} from "features/auth";
import { LoaderFallback } from "features/common";

import {
    ApplicantPage,
    BillingFormPage,
    BillingSelectorPage,
    BillingSuccessPage,
    BusinessPage,
    ErrorNotFound,
    ErrorPage,
    FasterFundingPage,
    HomePage,
    LegalBusinessPage,
    PlatformPage,
    ShippingPage,
    SiteSurveyPage,
    SuccessPage,
} from "../components";
import { IPageComponentProps } from "../types";
import { AppRoutes } from "./routes.types";

const getRoutes: (pageProps: IPageComponentProps) => RouteObject[] = (
    pageProps,
) => {
    return [
        {
            errorElement: (
                <PageLayout
                    {...pageProps}
                    shouldShowAuthIcon
                    shouldShowProgressBlock={false}
                    className="h-[100svh]"
                >
                    <ErrorPage appStore={pageProps.appStore} />
                </PageLayout>
            ),
            children: [
                {
                    element: (
                        <LoaderFallback promiseKey="isLoaded">
                            <Outlet />
                        </LoaderFallback>
                    ),
                    children: [
                        ...[
                            {
                                path: AppRoutes.Quote,
                                element: <PlatformPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.BusinessInformation,
                                element: <BusinessPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.LegalBusiness,
                                element: <LegalBusinessPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.Applicant,
                                element: <ApplicantPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.Owners,
                                element: <OwnersPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.Shipping,
                                element: <ShippingPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.BillingSelector,
                                element: <BillingSelectorPage {...pageProps} />,
                                loader: loadBillingSelector(pageProps.appStore),
                            },
                            {
                                path: AppRoutes.BillingForm,
                                element: <BillingFormPage {...pageProps} />,
                                loader: loadManualAccountData(
                                    pageProps.appStore,
                                ),
                            },
                            {
                                path: AppRoutes.BillingSuccess,
                                element: <BillingSuccessPage {...pageProps} />,
                                loader: loadLinkedAccount(pageProps.appStore),
                            },
                            {
                                path: AppRoutes.FasterFunding,
                                element: <FasterFundingPage {...pageProps} />,
                            },
                            {
                                path: AppRoutes.Signature,
                                pageLayoutClassName: clsx({
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    "h-[100svh]":
                                        !pageProps.appStore.state.data.config
                                            .clickToSign,
                                }),
                                element: (
                                    <SignatureResolverPage {...pageProps} />
                                ),
                            },
                            {},
                        ].map((route) => ({
                            ...route,
                            element: (
                                <PageLayout
                                    {...pageProps}
                                    shouldShowAuthIcon
                                    shouldShowProgressBlock
                                    className={route.pageLayoutClassName}
                                >
                                    <LoaderFallback promiseKey="isLoaded">
                                        {route.element}
                                    </LoaderFallback>
                                </PageLayout>
                            ),
                            loader: loadLoaders([
                                loadConfig(pageProps.appStore),
                                ...(route.loader ? [route.loader] : []),
                            ]),
                        })),
                        {
                            loader: loadConfig(pageProps.appStore),
                            element: (
                                <PageLayout
                                    {...pageProps}
                                    shouldShowAuthIcon
                                    shouldShowProgressBlock={false}
                                    isFullPage
                                >
                                    <Outlet />
                                </PageLayout>
                            ),
                            children: [
                                {
                                    path: "/",
                                    element: <HomePage {...pageProps} />,
                                },
                                {
                                    path: AppRoutes.Success,
                                    element: <SuccessPage {...pageProps} />,
                                },
                            ],
                        },
                    ],
                },
                { path: "*", element: <ErrorNotFound /> },
                {
                    loader: loadSiteSurvey,
                    path: AppRoutes.SiteSurvey,
                    element: (
                        <LoaderFallback promiseKey="siteSurveyResponsePromise">
                            <SiteSurveyPage />
                        </LoaderFallback>
                    ),
                    errorElement: <ErrorNotFound />,
                },
            ],
        },
    ] satisfies RouteObject[];
};

export const createRouter = (pageProps: IPageComponentProps) =>
    createBrowserRouter(getRoutes(pageProps));
