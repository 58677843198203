import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";

import { NumberField, TextField } from "features/common";
import { adjustRate, ShippingStepAdjustMode } from "features/utils";

import { TaxPreview } from "../../ShippingInformationForm.types";
import { validateAddTax } from "./AddTax.validations";
import { CommonModal } from "./CommonModal.component";

import styles from "./ConfirmTax.module.scss";

type AddTaxModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onAddTax: (tax: Omit<TaxPreview, "id">) => Promise<void>;
    isLoading?: boolean;
};

export const AddTaxModal = ({
    isVisible,
    onClose,
    onAddTax,
    isLoading,
}: AddTaxModalProps) => {
    const { t } = useTranslation();
    const formikRef = useRef<FormikProps<Omit<TaxPreview, "id">> | null>(null);

    return (
        <CommonModal
            isOpen={isVisible}
            onClose={onClose}
            title={t("addTax")}
            subtitle={t("addTaxSubtitle")}
            isConfirmLoading={isLoading}
            onConfirm={async () => {
                await formikRef.current?.submitForm();
            }}
        >
            <Formik
                innerRef={formikRef}
                initialValues={{ name: "", rate: NaN }}
                onSubmit={async (values) => {
                    const adjustedRates = {
                        ...values,
                        rate: adjustRate(
                            values.rate,
                            ShippingStepAdjustMode.FormOut,
                        ),
                    };
                    await onAddTax(adjustedRates);
                    onClose();
                }}
                validationSchema={validateAddTax(t)}
            >
                {(formik: FormikProps<Omit<TaxPreview, "id">>) => {
                    return (
                        <Form className="flex flex-col tablet:flex-row gap-4">
                            <div className="flex-[3]">
                                <TextField
                                    label={t("taxName")}
                                    isDisabled={isLoading}
                                    placeholder={t("taxNamePlaceholder")}
                                    value={formik.values.name}
                                    formik={formik}
                                    name="name"
                                    className="py-0 [&>div>input]:text-[1rem]"
                                />
                            </div>
                            <div className="flex-[1]">
                                <NumberField
                                    className={clsx(
                                        "tablet:[&>div>div>input]:text-right py-0 [&>span]:break-normal",
                                        styles.NumberField,
                                        styles.TextAlignLeftOnMobile,
                                    )}
                                    formik={formik}
                                    name="rate"
                                    value={formik.values.rate}
                                    label={t("rateLabel")}
                                    placeholder={t("taxRatePlaceholder", {
                                        rate: 0.2,
                                    })}
                                    suffix="%"
                                    allowNegative={false}
                                    decimalScale={3}
                                    maxLength={10}
                                    isClearable={false}
                                    isDisabled={isLoading}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </CommonModal>
    );
};
