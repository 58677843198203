/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { Radio } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField } from "formik";

import { IRadioFieldProps } from "./RadioField.types";

export function RadioField<T>(props: IRadioFieldProps<T>) {
    const {
        id,
        name,
        value,
        label,
        className,
        onChange,
        checked,
        formik: { setFieldTouched, setFieldValue },
        disabled,
    } = props;
    const [field] = useField(name);

    return (
        <Radio
            {...field}
            data-testid={id || `${name}-${value}-field`}
            className={clsx(
                className,
                "!text-sm mx-0 my-2 [&>label>span:nth-of-type(2)]:mt-[2px]",
            )}
            value={value}
            disabled={disabled}
            label={label}
            onChange={(event) => {
                const target = event.target as HTMLInputElement;
                let newValue: typeof value = target?.value;
                newValue =
                    newValue && typeof value === "number"
                        ? +newValue
                        : newValue;
                setFieldValue(
                    name,
                    typeof value === "number"
                        ? newValue && +newValue
                        : newValue,
                );
                setFieldTouched(name);
                if (onChange) {
                    onChange(event);
                }
            }}
            checked={checked}
        />
    );
}

export default RadioField;
