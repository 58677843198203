import React from "react";

import { useTranslation } from "i18nConfig";

import { hardwareAndImplementationColumns, softwareColumns } from "../columns";
import { IPlatformStep } from "../PlatformView.types";
import { QuoteSections } from "../types";
import { TableView } from "./TableView.component";

type DesktopDetailsViewProps = {
    data: IPlatformStep;
};

export const DesktopDetailsView = (props: DesktopDetailsViewProps) => {
    const { data } = props;
    const { t } = useTranslation();
    return (
        <div className="w-full flex flex-col justify-start gap-[2.75rem] pb-20">
            <TableView
                title={t("hardware")}
                data={data.hardware ?? []}
                columns={hardwareAndImplementationColumns}
                id={QuoteSections.HARDWARE}
            />
            <TableView
                data={data.implementation ?? []}
                title={t("implementation")}
                columns={hardwareAndImplementationColumns}
                id={QuoteSections.IMPLEMENTATION}
            />
            <TableView
                data={data.software ?? []}
                title={t("monthlySubscriptionFees")}
                columns={softwareColumns}
                id={QuoteSections.SOFTWARE}
            />
        </div>
    );
};
