import { useState } from "react";

import { countriesAndStates } from "./constants";
import { IStates } from "./types";

// utilities
export function useCountrySelect(
    defaultValue?: string,
): [
    IStates[],
    (value: string) => void,
    React.Dispatch<React.SetStateAction<IStates[]>>,
] {
    const getStates = (value: string): IStates[] => {
        return countriesAndStates.filter(
            (country) => country.value === value,
        )[0].states;
    };
    // for dropdown state options based on country
    const [stateOptions, setStateOptions] = useState<IStates[]>(
        defaultValue ? getStates(defaultValue) : [],
    );

    const onChange = (value: string) => {
        const statesList = getStates(value);
        if (statesList) setStateOptions(statesList);
    };

    return [stateOptions, onChange, setStateOptions];
}
