import { TFunction } from "i18next";
import * as Yup from "yup";

import { validateZip } from "features/common/utils";
import { Namespace } from "i18nConfig/translation.types";

export const zipChangeValidation = (
    t: TFunction<Namespace, undefined, Namespace>,
) => {
    return Yup.object().shape({
        zip: validateZip(
            Yup.string(),
            t("invalidZipValidationMessage"),
            t("requiredValidationMessage"),
        ),
    });
};
