import React, { useEffect, useState } from "react";

import { IPageComponentProps } from "app/types";
import {
    DownloadQuotePDFButton,
    PlatformView,
} from "features/application-wizard";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepPageLayout } from "../StepPageLayout";

export const PlatformPage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getState = useDerivedState(
        appStore,
        ({
            data: {
                config: { isPayments },
            },
        }) => ({
            isPayments,
        }),
    )[0];
    const { isPayments } = getState();

    useEffect(() => {
        const getPlatformItems = async () => {
            if (isPayments) {
                await Promise.all([
                    appStore.asyncDispatch.getPlatformStepProcessingItems(),
                    appStore.asyncDispatch.getPlatformStepLineItems(),
                ]);
            } else {
                await appStore.asyncDispatch.getPlatformStepLineItems();
            }
            setIsLoading(false);
        };
        getPlatformItems();
    }, []);

    return (
        <StepPageLayout
            appStore={appStore}
            id="platform-step"
            title={t("quotePageTitle")}
            customTitleIcon={<DownloadQuotePDFButton />}
            isLoading={isLoading}
            formikStore={formikStore}
            width="narrow"
        >
            <PlatformView appStore={appStore} formikStore={formikStore} />
        </StepPageLayout>
    );
};
