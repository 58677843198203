/* eslint-disable @typescript-eslint/naming-convention */
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { NumberField } from "features";

import { IZipCodeFieldProps } from "./ZipCodeField.types";

export function ZipCodeField<T>(props: IZipCodeFieldProps<T>) {
    const { t } = useTranslation();
    const { isClearable = false, onChange, ...restProps } = props;
    return (
        <NumberField
            onChange={async (event) => {
                onChange?.(event as ChangeEvent<HTMLInputElement>);
            }}
            isClearable={isClearable}
            placeholder={t("zipPlaceholder")}
            {...restProps}
            minLength={5}
            maxLength={5}
            decimalScale={0}
            allowNegative={false}
            allowLeadingZeros={true}
            parseToFloat={false}
        />
    );
}
