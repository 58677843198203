import React from "react";
import { IconButton } from "@spotoninc/nexus-react";
import { ArrowLeftIcon } from "@spotoninc/nexus-web-assets";
import clsx from "clsx";

import { CustomButton } from "app/components";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useStepNavigate } from "features";
import { AppStore } from "features/store";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import styles from "./SubHeader.module.scss";

export const SubHeader = ({
    appStore,
    isGoBackHidden,
    customGoBackButtonProps,
}: {
    appStore: AppStore;
    isGoBackHidden?: boolean;
    customGoBackButtonProps?: CustomButton;
}) => {
    const { t } = useTranslation();
    const { onClick, className } = customGoBackButtonProps || {};
    const [getState] = useDerivedState(
        appStore,
        ({
            loading: { isGoBackLoading, isSetConfigLoading },
            data: {
                progressBlock: {
                    navigation: { activeStepName },
                },
            },
        }) => ({
            isLoading: isGoBackLoading || isSetConfigLoading,
            activeStepName,
        }),
    );

    const { isLoading, activeStepName } = getState();
    const { canGoBack } = useStepNavigate({ appStore });

    return canGoBack && !isGoBackHidden ? (
        <div className={styles.SubHeader}>
            <div className="flex flex-start w-full items-center">
                <IconButton
                    size="medium"
                    icon={ArrowLeftIcon}
                    className={clsx("hidden tablet:flex", className)}
                    label={t("backBtn")}
                    variant="secondary"
                    {...getAnalyticProps(AnalyticElements.GoBackButton, {
                        context: "stepPage",
                        step: activeStepName,
                    })}
                    isLoading={isLoading}
                    onClick={() => onClick?.()}
                />
                <span className="pl-4 hidden tablet:flex">{t("backBtn")}</span>
            </div>
        </div>
    ) : null;
};
