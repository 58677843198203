import React, { forwardRef, MutableRefObject, ReactNode } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { CloseIcon } from "@spotoninc/nexus-web-assets";
import clsx from "clsx";

import { Breakpoint, useBreakpoint } from "features/hooks";

type ToggletipProps = {
    content?: string;
    isOpen?: boolean;
    onClose?: () => void;
    trigger?: ReactNode;
};
// eslint-disable-next-line react/display-name
export const Toggletip = forwardRef<HTMLElement, ToggletipProps>(
    ({ content, isOpen, onClose, trigger }, ref) => {
        const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
        return (
            <Tooltip.Provider>
                <Tooltip.Root open={isOpen}>
                    <Tooltip.Trigger>
                        <span className="flex items-center text-left" ref={ref}>
                            {trigger}
                        </span>
                    </Tooltip.Trigger>
                    <Tooltip.Portal
                        container={
                            (ref as MutableRefObject<HTMLElement | null>)
                                ?.current
                        }
                    >
                        <Tooltip.Content
                            onPointerDownOutside={onClose}
                            className={clsx(
                                "bg-container-neutral-lightest rounded-lg p-4 pr-3 max-w-[calc(100vw-3rem)] shadow-[0_1px_2px_0_rgba(0,0,0,0.16),0_2px_6px_2px_rgba(0,0,0,0.08)]",
                            )}
                            side={isLowerThanTablet ? "bottom" : "right"}
                            align={isLowerThanTablet ? "start" : "center"}
                            sideOffset={8}
                        >
                            <div className="flex gap-1 max-w-screen">
                                <div
                                    className={clsx(
                                        "text-sm max-w-[250px] text-left",
                                    )}
                                >
                                    {content}
                                </div>
                                <div className="flex items-start justify-center">
                                    <button
                                        onClick={onClose}
                                        className="!p-[3px] !h-fit"
                                    >
                                        <CloseIcon className="h-[18px] w-[18px]" />
                                    </button>
                                </div>
                            </div>
                            {!isLowerThanTablet && (
                                <Tooltip.Arrow className="fill-container-neutral-lightest" />
                            )}
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        );
    },
);
