import React, { ReactNode, useState } from "react";
import { GhostButton } from "@spotoninc/nexus-react";
import { InformativeIcon } from "@spotoninc/nexus-web-assets";

import { Toggletip } from "features/common";
import { useTranslation } from "i18nConfig";

type LabelWithDetailsProps = {
    label: ReactNode;
    details?: string;
    onClick: () => void;
};

export const LabelWithDetails = ({
    label,
    details,
    onClick,
}: LabelWithDetailsProps) => {
    const { t } = useTranslation();
    return (
        <span className="flex items-center flex-wrap">
            <span className="pr-3.5">{label}</span>
            <span className="flex items-center">
                <GhostButton as="a" onClick={onClick}>
                    {details ?? t("detailsText")}
                </GhostButton>
            </span>
        </span>
    );
};

type LabelWithToggletipProps = {
    label: ReactNode;
    info?: string;
};

export const LabelWithToggletip = ({
    label,
    info,
}: LabelWithToggletipProps) => {
    const [isOpen, setIsToggletipOpen] = useState<boolean>(false);
    return (
        <Toggletip
            isOpen={isOpen}
            onClose={() => setIsToggletipOpen(false)}
            content={info}
            trigger={
                <div className="inline">
                    <span className="pr-2">{label}</span>
                    <button
                        onClick={() => setIsToggletipOpen(true)}
                        className="text-neutral-60 inline align-sub"
                    >
                        <InformativeIcon />
                    </button>
                </div>
            }
        />
    );
};
