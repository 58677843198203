// builds a field which is not required if ownerStatus is not "owner".
// We only want to validate additionalOwners if they are visible.
import moment from "moment/moment";
import * as Yup from "yup";

import { itinOrSsnRegex } from "features/application-wizard";
import { DateFormat } from "features/common/types";
import {
    maxLengthValidation,
    validateEmail,
    validatePhone,
    validateZip,
} from "features/common/utils";

import { OwnerStatus } from "../ApplicantForm";
import { IOwnerType, IOwnerValidationSchema } from "./owners.types";

export const formatSSNAndItin = (value: string) => {
    const numeric = value.replace(/\D/g, "");

    let masked = numeric.replace(/^(\d{3})(\d{2})/, "***-**-");

    masked = masked.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
    return masked;
};

export const formatEin = (value: string) => {
    const numeric = value.replace(/\D/g, "");

    let masked = numeric.replace(/^(\d{2})(\d{3})/, "**-***");

    masked = masked.replace(/(\d{4})/, "$1");
    return masked;
};

// schema for singer owner form

const resolveDeletedValidation = <
    T extends Yup.Schema<any, { isDeleted?: boolean }>,
>(
    baseSchema: T,
    validation: (schema: T) => T,
) => {
    return baseSchema.when("isDeleted", {
        is: (value: boolean) => value,
        then: (schema: T) => schema.notRequired(),
        otherwise: (schema) => {
            return validation(schema);
        },
    });
};

export const ownerValidationSchema = ({
    t,
    percentageFromOtherStep,
    isAdditionalOwners,
    ownerStatus,
}: IOwnerValidationSchema) => {
    return Yup.object().shape({
        isDeleted: Yup.boolean().nullable(),
        contact: resolveDeletedValidation(Yup.object(), (schema) =>
            schema.shape({
                firstName: Yup.string()
                    .trim()
                    .concat(maxLengthValidation(100, t))
                    .required(t("requiredValidationMessage")),
                lastName: Yup.string()
                    .trim()
                    .concat(maxLengthValidation(100, t))
                    .required(t("requiredValidationMessage")),
                email: validateEmail(t("requiredValidationMessage"), t),
                phone: validatePhone(
                    t("invalidPhoneNumberValidationMessage"),
                    t("requiredValidationMessage"),
                    false,
                ),
            }),
        ),
        ownershipPercentage: resolveDeletedValidation(Yup.number(), (schema) =>
            schema
                .max(
                    100,
                    `${t(
                        "invalidValueCantBeGreaterThanValidationMessage",
                    )} 100%`,
                )
                .when([], {
                    is: () => ownerStatus === OwnerStatus.ResponsibleParty,
                    // 0% of ownership is acceptable for responsible party
                    then: (ownershipPercentage) => ownershipPercentage.min(0),
                    // must be >0% otherwise
                    otherwise: (ownershipPercentage) =>
                        ownershipPercentage.positive(
                            t("invalidValueMustBePositiveValidationMessage"),
                        ),
                })
                .when([], {
                    is: () => ownerStatus === OwnerStatus.PartOwner,
                    then: (ownershipPercentage) =>
                        ownershipPercentage.min(
                            25,
                            t("invalidValueMustBeGreatedThan25Message"),
                        ),
                })
                .when([], {
                    is: () => ownerStatus !== OwnerStatus.Owner,
                    then: (schema) =>
                        schema
                            .typeError(t("requiredValidationMessage"))
                            .required(t("requiredValidationMessage")),
                })
                .when([], {
                    is: () => isAdditionalOwners,
                    then: (schema) =>
                        schema
                            .min(
                                25,
                                t("invalidValueMustBeGreatedThan25Message"),
                            )
                            .typeError(t("requiredValidationMessage")),
                })
                .test({
                    name: "sum-of-ownership-percentage",
                    test: function (val, meta) {
                        const value: number = val || 0;
                        if (isAdditionalOwners) {
                            const { from } = meta;
                            const totalOwnershipPercentage =
                                from?.[1].value.owners
                                    .filter(
                                        (owner: IOwnerType) => !owner.isDeleted,
                                    )
                                    .reduce((acc: number, obj: IOwnerType) => {
                                        const percentage =
                                            obj.ownershipPercentage || 0;
                                        return acc + Number(percentage);
                                    }, percentageFromOtherStep);
                            return totalOwnershipPercentage > 100
                                ? this.createError({
                                      message:
                                          percentageFromOtherStep > 0
                                              ? t(
                                                    "invalidValueTotalOwnershipCantBeGreaterThan100OtherStep",
                                                    {
                                                        maxOwnershipAllowed:
                                                            100 -
                                                            totalOwnershipPercentage +
                                                            value,
                                                    },
                                                )
                                              : t(
                                                    "invalidValueTotalOwnershipCantBeGreaterThan100",
                                                ),
                                      path: meta.path,
                                  })
                                : true;
                        } else {
                            return percentageFromOtherStep + value > 100 &&
                                value <= 75
                                ? this.createError({
                                      message: t(
                                          "invalidValueTotalOwnershipCantBeGreaterThan100OtherStep",
                                          {
                                              maxOwnershipAllowed:
                                                  100 - percentageFromOtherStep,
                                          },
                                      ),
                                      path: meta.path,
                                  })
                                : true;
                        }
                    },
                }),
        ),
        title: resolveDeletedValidation(Yup.string(), (schema) =>
            schema.required(t("requiredValidationMessage")),
        ),
        ssn: resolveDeletedValidation(Yup.string(), (schema) =>
            schema
                .required(t("requiredValidationMessage"))
                .matches(
                    itinOrSsnRegex,
                    t("invalidSSNNumberValidationMessage"),
                ),
        ),
        dob: resolveDeletedValidation(Yup.string(), (schema) =>
            schema
                .nullable()
                .test("valid", t("invalidDateValidationMessage"), (val) => {
                    return moment(val, DateFormat.MM_DD_YYYY, true).isValid();
                })
                .test("length", t("invalidDateValidationMessage"), (val) => {
                    const valueWithoutMask = val?.replace(/[-._/]/g, "").length;
                    return valueWithoutMask === 8;
                })
                .test(
                    "DOB",
                    t("invalidBirthDateTooYoungValidationMessage"),
                    (value) => {
                        return (
                            moment().diff(
                                moment(value, DateFormat.MM_DD_YYYY),
                                "years",
                            ) >= 18
                        );
                    },
                )
                .test(
                    "DOB",
                    t("invalidBirthDateTooOldValidationMessage"),
                    (value) => {
                        return (
                            moment().diff(
                                moment(value, DateFormat.MM_DD_YYYY),
                                "years",
                            ) < 120
                        );
                    },
                )
                .required(t("requiredValidationMessage")),
        ),
        address: resolveDeletedValidation(Yup.object(), (schema) =>
            schema.shape({
                address1: Yup.string()
                    .trim()
                    .concat(maxLengthValidation(100, t))
                    .required(t("requiredValidationMessage")),
                address2: Yup.string().concat(maxLengthValidation(100, t)),
                city: Yup.string()
                    .trim()
                    .concat(maxLengthValidation(100, t))
                    .required(t("requiredValidationMessage")),
                state: Yup.string().required(t("requiredValidationMessage")),
                zip: validateZip(
                    Yup.string(),
                    t("invalidZipValidationMessage"),
                    t("requiredValidationMessage"),
                ),
                country: Yup.string().required(t("requiredValidationMessage")),
            }),
        ),
    });
};
