import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GhostButton } from "@spotoninc/nexus-react";
import { showToast } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { FormikProps, useField } from "formik";

import { AddIcon } from "app/icons";
import { NumberField } from "features/common";
import { AppStore } from "features/store";
import { useDerivedState } from "store/hooks";

import {
    IShippingStep,
    SuggestedTaxes,
    TaxPreview,
} from "../../ShippingInformationForm.types";
import { AdditionalTaxItem } from "./AdditionalTaxItem.component";
import { AddTaxModal } from "./AddTaxModal.component";
import { GreyTaxContainer } from "./GrayTaxContainer.component";
import { areSuggestedTaxesFound, formatPercent } from "./helpers";
import { TaxItem } from "./TaxItem.component";
import { ZipChangeModal } from "./ZipChangeModal";

import styles from "./ConfirmTax.module.scss";

export const ConfirmTax = ({
    formik,
    appStore,
}: {
    formik: FormikProps<IShippingStep>;
    appStore: AppStore;
}) => {
    const { t } = useTranslation();
    const [zip, setZip] = useState<string>(
        appStore.state.data.shippingStep.suggestedTaxRate?.zipCode ?? "",
    );
    const [isChangeZipModalVisible, setIsChangeZipModalVisible] =
        useState(false);
    const [isAdditionalTaxModalVisible, setIsAdditionalTaxModalVisible] =
        useState(false);

    const [getState] = useDerivedState(
        appStore,
        ({
            loading: {
                isSetSuggestedLocalTaxesLoading,
                isSetAdditionalTaxLoading,
                isRemoveAdditionalTaxLoading,
            },
            data: {
                shippingStep: {
                    additionalTaxes,
                    suggestedTaxRate,
                    salesTaxRate,
                },
            },
        }) => ({
            additionalTaxes,
            suggestedTaxRate,
            salesTaxRate,
            isSetSuggestedLocalTaxesLoading,
            isSetAdditionalTaxLoading,
            isRemoveAdditionalTaxLoading,
        }),
    );
    const {
        additionalTaxes,
        suggestedTaxRate,
        isSetSuggestedLocalTaxesLoading,
        isSetAdditionalTaxLoading,
        isRemoveAdditionalTaxLoading,
    } = getState();

    const [field, meta] = useField("salesTaxRate");

    const onDeleteAdditionalTax = async (tax: TaxPreview) => {
        await appStore.asyncDispatch.removeAdditionalTax(tax.id, () => {
            showToast({
                priority: "success",
                content: t("taxRemovedMessage"),
            });
        });
    };

    return (
        <div className="flex flex-col">
            {isChangeZipModalVisible && (
                <ZipChangeModal
                    isOpen={isChangeZipModalVisible}
                    onClose={() => setIsChangeZipModalVisible(false)}
                    initialZip={zip}
                    key={zip}
                    onZipChange={async (
                        newZip: string,
                        suggestedTaxes: SuggestedTaxes,
                    ) => {
                        setZip(newZip);
                        if (areSuggestedTaxesFound(suggestedTaxes)) {
                            await appStore.asyncDispatch.setSuggestedLocalTaxes(
                                suggestedTaxes,
                                true,
                            );
                        }
                    }}
                    isConfirmLoading={isSetSuggestedLocalTaxesLoading}
                />
            )}
            {isAdditionalTaxModalVisible && (
                <AddTaxModal
                    isVisible={isAdditionalTaxModalVisible}
                    onClose={() => setIsAdditionalTaxModalVisible(false)}
                    onAddTax={async (tax) => {
                        await appStore.asyncDispatch.setAdditionalTax(tax);
                    }}
                    isLoading={isSetAdditionalTaxLoading}
                />
            )}
            <div className="flex flex-col gap-6">
                <div className="text-left">
                    <h5 className="text-xl font-bold">
                        {t("confirmYourSalesTaxRate")}
                    </h5>
                    <p className="text-sm">{t("salesTaxDescription")}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col">
                        <div className="flex justify-between items-center gap-1">
                            <span className="font-[600]">
                                {t("taxRateSection")}
                            </span>
                            <NumberField
                                className={clsx(
                                    styles.NumberField,
                                    "max-w-[113px]",
                                )}
                                formik={formik}
                                name="salesTaxRate"
                                value={field.value}
                                label=""
                                placeholder={t("taxRatePlaceholder", {
                                    rate: 6.25,
                                })}
                                suffix="%"
                                allowNegative={false}
                                decimalScale={3}
                                maxLength={10}
                                isClearable={false}
                                hideErrorMessage
                            />
                        </div>
                        <div className="flex justify-end text-danger-danger-60 text-sm">
                            <span>{meta.touched && meta.error}</span>
                        </div>
                    </div>

                    <GreyTaxContainer
                        header={
                            suggestedTaxRate?.totalRate ? (
                                <span>
                                    {`${t("suggestedTaxRateMessage", {
                                        rate: formatPercent(
                                            suggestedTaxRate?.totalRate,
                                        ),
                                    })} `}
                                    <span className="font-bold">
                                        {suggestedTaxRate?.zipCode}.
                                    </span>
                                </span>
                            ) : (
                                <span>
                                    {`${t("noTaxRateFoundMessage")} `}
                                    <span className="font-bold">{zip}.</span>
                                </span>
                            )
                        }
                    >
                        <div>
                            {suggestedTaxRate?.rates.map((item) => (
                                <TaxItem
                                    key={`${item.name}_${item.rate}_${item.type}`}
                                    tax={item}
                                />
                            ))}
                        </div>
                        <div>
                            <GhostButton
                                onClick={() => setIsChangeZipModalVisible(true)}
                            >
                                {t("changeZip")}
                            </GhostButton>
                        </div>
                    </GreyTaxContainer>
                </div>
                <div>
                    <div className="text-left min-h-[44px]">
                        <span className="font-[600]">
                            {t("additionalMenuItemTaxes")}
                        </span>
                    </div>
                    <GreyTaxContainer header={t("optionalTaxesDescription")}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-0.5">
                                {additionalTaxes.map((tax) => (
                                    <AdditionalTaxItem
                                        key={tax.id}
                                        tax={tax}
                                        onDelete={onDeleteAdditionalTax}
                                        isLoading={isRemoveAdditionalTaxLoading}
                                    />
                                ))}
                            </div>
                            <GhostButton
                                onClick={() =>
                                    setIsAdditionalTaxModalVisible(true)
                                }
                                leadingIcon={AddIcon}
                            >
                                {t("addTax")}
                            </GhostButton>
                        </div>
                    </GreyTaxContainer>
                </div>
            </div>
        </div>
    );
};
