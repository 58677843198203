import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { Uploader } from "@spotoninc/nexus-react";
import { BodyText } from "@spotoninc/nexus-react";
import { Form, Formik, FormikProps } from "formik";

import { ISiteSurveyQuestions } from "app/components/SiteSurveyPage/SiteSurveyPage.types";
import { SiteSurveyContext } from "app/components/SiteSurveyPage/SiteSurveyPage.utils";
import { ScrollToFieldError, TextAreaField } from "features";
import { YesNoRadioField } from "features/common/components/YesNoRadioField";
import { base64ToWebpFile } from "features/common/utils";
import { useTranslation } from "i18nConfig";

import { TextAreaFields, YesNoFields } from "./SiteSurvey.consts";
import { postSiteSurvey, removeFile, uploadFile } from "./SiteSurvey.service";
import {
    IFileWithId,
    ISiteSurveyImperativeHandle,
    SiteSurveyProps,
} from "./SiteSurvey.types";
import {
    getQuestionTranslation,
    getValidationSchema,
    initialQuestionsAndAnswers,
    renderDescription,
} from "./SiteSurvey.utils";

const SiteSurveyComponent = (
    { onSurveyCompleted, onSubmitting }: SiteSurveyProps,
    ref: React.Ref<ISiteSurveyImperativeHandle>,
) => {
    const { t } = useTranslation();

    const formikRef = React.useRef<FormikProps<ISiteSurveyQuestions>>(null);
    useImperativeHandle(ref, () => ({
        submitForm: () => {
            formikRef.current?.submitForm();
        },
    }));

    const { businessName, photos } = useContext(SiteSurveyContext) || {};
    let filesWithIds: IFileWithId[] = [];
    if (photos) {
        filesWithIds = photos.map(({ id, base64 }) => {
            const file = base64ToWebpFile(
                base64,
                `photo${id}.webp`,
            ) as IFileWithId;
            file.id = id;
            return file;
        });
    }
    const [uploadedFiles, setUploadedFiles] =
        useState<IFileWithId[]>(filesWithIds);
    const [searchParams] = useSearchParams();
    const siteSurveyId = searchParams.get("id");

    const handleOnUpload = async (files: File[]) => {
        try {
            if (siteSurveyId) {
                const uploadedFiles = await Promise.all(
                    files.map((file) => uploadFile(siteSurveyId, file)),
                );
                setUploadedFiles((prev) => [...prev, ...uploadedFiles]);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    const handleOnRemove = async (file: File) => {
        const photoId = (file as IFileWithId).id;
        try {
            if (siteSurveyId) {
                await removeFile(siteSurveyId, photoId);
                setUploadedFiles((prev) =>
                    prev.filter((f) => f.id !== photoId),
                );
            }
        } catch (error) {
            console.error("Error removing file:", error);
        }
    };

    const handleOnSubmit = async (values: ISiteSurveyQuestions) => {
        onSubmitting(true);
        const questionsAndAnswers = [];
        for (const [key, answer] of Object.entries(values)) {
            questionsAndAnswers.push({
                question: getQuestionTranslation(
                    key as keyof ISiteSurveyQuestions,
                    { businessName },
                ),
                answer,
            });
        }
        try {
            if (siteSurveyId) {
                await postSiteSurvey(siteSurveyId, questionsAndAnswers);
                onSurveyCompleted();
            }
        } catch (error) {
            console.error("Error submitting site survey:", error);
        } finally {
            onSubmitting(false);
        }
    };

    return (
        <div className="pt-5">
            <Formik
                innerRef={formikRef}
                initialValues={initialQuestionsAndAnswers}
                validationSchema={getValidationSchema(t)}
                onSubmit={handleOnSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {(formik) => (
                    <Form>
                        <ScrollToFieldError formik={formik} />
                        <div className="flex flex-col gap-16">
                            <div className="flex flex-col gap-8">
                                {TextAreaFields.map((field) => (
                                    <TextAreaField
                                        key={field}
                                        formik={formik}
                                        name={field}
                                        className="p-0 [&>div:nth-of-type(2)]:h-[142px] [&>div:nth-of-type(2)]:resize-none [&_label]:text-left [&_label]:pb-1 [&_label>span]:!text-base !font-medium whitespace-normal"
                                        label={getQuestionTranslation(field, {
                                            businessName,
                                        })}
                                        value={formik.values[field]}
                                        placeholder={getQuestionTranslation(
                                            field,
                                            {},
                                            true,
                                        )}
                                    />
                                ))}
                            </div>
                            <div className="flex flex-col gap-8 text-left">
                                <div className="flex flex-col gap-3">
                                    <h2 className="text-[28px] leading-9 font-semibold">
                                        {t("siteSurveyFormTitle")}
                                    </h2>
                                    <BodyText
                                        size="medium"
                                        className="font-normal text-neutral-60"
                                    >
                                        {t("siteSurveyFormRequiredDescription")}
                                    </BodyText>
                                </div>
                                {YesNoFields.map((field) => (
                                    <YesNoRadioField<ISiteSurveyQuestions>
                                        key={field}
                                        formik={formik}
                                        name={field}
                                        label={getQuestionTranslation(field)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className="flex flex-col gap-3 pt-16 pb-10 text-left">
                <h2 className="text-[28px] leading-9 font-semibold">
                    {t("siteSurveyUploadTitle")}
                </h2>
                <Uploader
                    files={uploadedFiles}
                    onUpload={handleOnUpload}
                    onRemove={handleOnRemove}
                    accept={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        "image/png": [".jpeg", ".jpg", ".png", ".webp"],
                    }}
                    maxSize={5000 * 1000}
                    label=""
                    renderDescription={renderDescription}
                    className="border-none p-0 [&>*>button]:rounded [&>*>button]:!bg-white [&>*>button]:!text-primary-primary-50 [&>*>button]:!border [&>*>button]:!border-solid [&>*>button]:!border-[#1769ff] [&>*>button]:w-full [&>*>button]:justify-center"
                />
            </div>
        </div>
    );
};

export const SiteSurvey = forwardRef(SiteSurveyComponent);
