/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { BodyText, Checkbox } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { useField } from "formik";

import { ICheckboxFieldProps } from "./CheckboxField.types";

import styles from "./CheckboxField.module.scss";

export function CheckboxField<T>(props: ICheckboxFieldProps<T>) {
    const {
        id,
        name,
        value,
        label,
        labelComponent,
        className,
        onChange,
        checked,
        formik: { setFieldTouched, setFieldValue },
        isDisabled = false,
        customContainerClassName,
    } = props;
    const [field, meta] = useField(name);

    return (
        <div
            className={clsx(
                customContainerClassName || styles.CheckboxField_container,
            )}
        >
            <Checkbox
                {...field}
                data-testid={id || `${name}-field`}
                className={`${className} [&>span]:!text-sm !px-0 !mx-0`}
                disabled={isDisabled}
                value={value}
                label={labelComponent ? "" : label}
                onChange={(event) => {
                    const target = event.target as HTMLInputElement;
                    setFieldValue(name, target?.value);
                    setFieldTouched(name);
                    if (onChange) {
                        onChange(event);
                    }
                }}
                checked={checked}
            />
            <div className={clsx(styles.CheckboxField_labelAndError)}>
                {labelComponent}
                <div
                    className={clsx(
                        styles.CheckboxField_errorContainer,
                        customContainerClassName && "pt-0",
                    )}
                >
                    <BodyText
                        size="small"
                        className="text-danger-danger-70 text-sm"
                    >
                        {(meta.touched && meta.error) || " "}
                    </BodyText>
                </div>
            </div>
        </div>
    );
}

export default CheckboxField;
